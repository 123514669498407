import { axiosInstance } from "../../../helper"
import { banner } from "../types"

export function deleteEventBanner(id, config) {
	return {
		type: banner.DELETE_EVENT_BANNER,
		payload: new axiosInstance.delete(`/event-banner/${id}`, config),
	}
}

export default deleteEventBanner
