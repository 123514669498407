import { instagram } from "../types"
import { axiosInstance } from "../../../helper"

export function changeIgProfile(body, config) {
	return {
		type: instagram.CHANGE_IG_PROFILE,
		payload: new axiosInstance.put("/admin/instagram-profile", body, config),
	}
}

export default changeIgProfile
