import { axiosInstance } from "../../../helper"
import { event } from "../types"

export function addEvent(body, config) {
	return {
		type: event.ADD_EVENT,
		payload: new axiosInstance.post(`/admin/event/`, body, config),
	}
}

export default addEvent
