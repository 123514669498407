import { axiosInstance } from "../../../helper"
import { church } from "../types"

export function addChurch(body, config) {
	return {
		type: church.ADD_CHURCH,
		payload: new axiosInstance.post(`/church/`, body, config),
	}
}

export default addChurch
