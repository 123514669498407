import getWorshipTime from "./getWorshipTime"
import addWorshipTime from "./addWorshipTime"
import deleteWorshipTime from "./deleteWorshipTime"
import updateWorshipTime from "./updateWorshipTime"

export { getWorshipTime, addWorshipTime, deleteWorshipTime }

const worshipTime = {
	getWorshipTime,
	addWorshipTime,
	deleteWorshipTime,
	updateWorshipTime,
}

export default worshipTime
