import { dashboard } from "../types"
import axiosInstance from "../../../helper/axiosInstance"

export function getScheduleChart(params, config) {
	return {
		type: dashboard.SCHEDULE,
		payload: new axiosInstance.get("/admin/dashboard/schedule-chart", {
			params,
			...config,
		}),
	}
}

export default getScheduleChart
