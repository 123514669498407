import { axiosInstance } from "../../../helper"
import { churchRecord } from "../types"

export function getDetailRecord(id, params, config) {
	return {
		type: churchRecord.GET_RECORD_DETAIL,
		payload: new axiosInstance.get(`/admin/church-record/${id}`, {
			...config,
			params,
		}),
	}
}

export default getDetailRecord
