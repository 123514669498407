import {
	Card,
	CardHeader,
	CardFooter,
	Table,
	Container,
	Row,
	BreadcrumbItem,
	Breadcrumb,
	Input,
} from "reactstrap"
// core components
import { Component } from "react"
import { Link } from "react-router-dom"
import ReactPaginate from "react-paginate"
import { connect } from "react-redux"
import { sacrament } from "../../../redux/actions"

class HolyMatrimony extends Component {
	constructor(props) {
		super(props)

		this.state = {
			params: {
				limit: 10,
				page: 1,
			},
		}
	}

	handlePageClick(e) {
		const oldState = { ...this.state }

		oldState.params.page = e.selected + 1

		this.setState(oldState, () => {
			this.getMatrimonial()
		})
	}

	getMatrimonial() {
		this.props.getMatrimonial(this.state.params)
	}

	componentDidMount() {
		this.getMatrimonial()
	}
	render() {
		return (
			<>
				{/* Page content */}
				<Container fluid className='mb-5'>
					<div>
						<Breadcrumb>
							<BreadcrumbItem>
								<Link to='/admin/sacrament'>Sakramen</Link>
							</BreadcrumbItem>
							<BreadcrumbItem active>Peneguhan Nikah</BreadcrumbItem>
						</Breadcrumb>
					</div>
					{/* Table */}
					<Row>
						<div className='col'>
							<Card>
								<CardHeader className='d-flex'>
									<h3 className='mr-auto'>Peneguhan Nikah</h3>
								</CardHeader>
								<CardHeader>
									<div className='row'>
										<div className='has-search col-xl-4'>
											<span className='fa fa-search form-control-feedback'></span>
											<Input
												type='text'
												className='form-control'
												placeholder='Cari berdasarkan nama'
												onKeyDown={(e) => {
													if (e.key === "Enter") {
														this.getMatrimonial()
													}
												}}
												onChange={(e) => {
													this.setState({
														params: Object.assign({}, this.state.params, {
															search: e.target.value,
														}),
													})
												}}
											/>
										</div>
									</div>
								</CardHeader>
								<Table
									className='align-items-center table-flush table-striped'
									responsive
								>
									<thead className='thead-light'>
										<tr>
											<th scope='col'>No.</th>
											<th scope='col'>Nama Mempelai Pria</th>
											<th scope='col'>Nama Mempelai Wanita</th>
											<th scope='col'>Tanggal Pernikahan</th>
											<th scope='col'>Action</th>
										</tr>
									</thead>
									<tbody>
										{this.props.matrimony.data.map((matrimony, i) => (
											<tr>
												<td>
													{(this.props.matrimony.page.currentPage - 1) *
														this.state.params.limit +
														i +
														1}
												</td>
												<td>{matrimony.groom.name}</td>
												<td>{matrimony.bride.name}</td>
												<td>{matrimony.date}</td>
												<td>
													<Link
														to={{
															pathname: `/admin/sacrament/holy-matrimony/${matrimony.id}`,
														}}
													>
														Lihat Detail
													</Link>
												</td>
											</tr>
										))}
									</tbody>
								</Table>
								<CardFooter className='py-4 d-flex justify-content-end align-items-center'>
									<p>
										{`Menampilkan ${this.props.matrimony.data.length} dari ${this.props.matrimony.page.totalData} data`}
									</p>
									<ReactPaginate
										breakLabel='...'
										nextLabel='&raquo;'
										onPageChange={this.handlePageClick.bind(this)}
										pageRangeDisplayed={1}
										pageCount={this.props.matrimony.page.totalPage}
										marginPagesDisplayed={1}
										previousLabel='&laquo;'
										renderOnZeroPageCount={null}
										className='pagination ml-3'
										pageClassName='page-item'
										pageLinkClassName='page-link'
										activeClassName='active'
										disabledClassName='disabled'
										nextClassName='page-item'
										breakClassName='page-item'
										nextLinkClassName='page-link'
										previousClassName='page-item'
										previousLinkClassName='page-link'
										breakLinkClassName='page-link'
									/>
								</CardFooter>
							</Card>
						</div>
					</Row>
				</Container>
			</>
		)
	}
}

const mapStateToProps = (state) => {
	return {
		matrimony: state.matrimony,
	}
}

const mapDispatchToProps = {
	getMatrimonial: sacrament.getMatrimonial,
}
export default connect(mapStateToProps, mapDispatchToProps)(HolyMatrimony)
