import { Component } from "react"
import { connect } from "react-redux"
import { Redirect, Route } from "react-router-dom"
import { decodeToken } from "../../helper"
class ProtectedRoute extends Component {
	render() {
		if (
			this.props.auth.data.token &&
			decodeToken(this.props.auth.data.token).is_admin
		) {
			return <Redirect to='/admin/index' />
		}

		return <Route {...this.props} />
	}
}

const mapStateToProps = (state) => {
	return { auth: state.auth }
}

export default connect(mapStateToProps)(ProtectedRoute)
