export const getMaritalStatus = (status) => {
	/*
			1 = Single;
			2 = Married;
			3 = Divorced;
			4 = Widowed;
			5 = Other;
			9 = Prefer Not to say;
			*/

	switch (status) {
		case 1:
			return "Belum Menikah"
		case 2:
			return "Sudah Menikah"
		default:
			return "Tidak Diketahui/Lainnya"
	}
}

export default getMaritalStatus
