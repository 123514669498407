import { axiosInstance } from "../../../helper"
import { secretariat } from "../types"

export function getItemDetail(id, params, config) {
	return {
		type: secretariat.GET_ITEM_DETAIL,
		payload: new axiosInstance.get(`/admin/secretariat-item/${id}`, {
			...config,
			params,
		}),
	}
}

export default getItemDetail
