import { axiosInstance } from "../../../helper"
import { event } from "../types"

export function updateEvent(id, data, config) {
	return {
		type: event.UPDATE_EVENT,
		payload: new axiosInstance.put(`/admin/event/${id}`, data, config),
	}
}

export default updateEvent
