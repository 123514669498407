import { axiosInstance } from "../../../helper"
import { account } from "../types"

export function getAccount(id, params, config) {
	return {
		type: account.GET_DETAIL_ACCOUNT,
		payload: new axiosInstance.get(`/admin/user/${id}`, {
			...config,
			params,
		}),
	}
}

export default getAccount
