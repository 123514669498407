import { axiosInstance } from "../../../helper"
import { ministry } from "../types"

export function addMinistry(body, config) {
	return {
		type: ministry.ADD_MINISTRY,
		payload: new axiosInstance.post(`/admin/ministry/`, body, config),
	}
}

export default addMinistry
