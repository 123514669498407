import { axiosInstance } from "../../../helper"
import { resource } from "../types"

export function updateResource(id, data, config) {
	return {
		type: resource.UPDATE_RESOURCE,
		payload: new axiosInstance.put(`/admin/resource/${id}`, data, config),
	}
}

export default updateResource
