import { axiosInstance } from "../../../helper"
import { event } from "../types"

export function getEvent(id, params, config) {
	return {
		type: event.DETAIL_EVENT,
		payload: new axiosInstance.get(`/admin/event/${id}`, {
			...config,
			params,
		}),
	}
}

export default getEvent
