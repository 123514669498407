/*!

=========================================================
* Argon Dashboard React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// reactstrap components
import { Component } from "react"
import { connect } from "react-redux"
import {
	DropdownMenu,
	DropdownItem,
	UncontrolledDropdown,
	DropdownToggle,
	Navbar,
	Nav,
	Container,
	Media,
} from "reactstrap"
import { auth, user } from "../../redux/actions"

class AdminNavbar extends Component {
	getProfile() {
		this.props.getProfile()
	}

	componentDidMount() {
		this.getProfile()
	}

	render() {
		return (
			<Navbar
				className='navbar-top navbar-light bg-white'
				expand='md'
				id='navbar-main'
			>
				<Container fluid>
					<div></div>
					<Nav className='align-items-center d-none d-md-flex' navbar>
						<UncontrolledDropdown nav>
							<DropdownToggle className='pr-0' nav>
								<Media className='align-items-center'>
									<i className='ni ni-single-02' />
									<Media className='ml-2 mr-2 d-none d-lg-block'>
										<span className='mb-0 text-sm font-weight-bold'>
											{this.props.user.profile.name}
										</span>
									</Media>
									<i className='fas fa-caret-down'></i>
								</Media>
							</DropdownToggle>
							<DropdownMenu className='dropdown-menu-arrow' right>
								<DropdownItem href='#pablo' onClick={this.props.logout}>
									<i className='ni ni-user-run' />
									<span>Logout</span>
								</DropdownItem>
							</DropdownMenu>
						</UncontrolledDropdown>
					</Nav>
				</Container>
			</Navbar>
		)
	}
}

const mapStateToProps = (state) => {
	return {
		user: state.user,
	}
}

const mapDispatchToProps = {
	logout: auth.logout,
	getProfile: user.getProfile,
}

export default connect(mapStateToProps, mapDispatchToProps)(AdminNavbar)
