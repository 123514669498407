import { axiosInstance } from "../../../helper"
import { account } from "../types"

export function deleteAccount(id, config) {
	return {
		type: account.DELETE,
		payload: new axiosInstance.delete(`/admin/user/${id}`, config),
	}
}

export default deleteAccount
