import getAccounts from "./getAccounts"
import getAccount from "./getAccount"
import deleteAccount from "./deleteAccount"
import addAccount from "./addAccount"
import updateAccount from "./updateAccount"

export { getAccounts, getAccount, addAccount, deleteAccount, updateAccount }

const account = { getAccounts, getAccount, addAccount, deleteAccount, updateAccount }
export default account
