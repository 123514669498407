import {
	Card,
	CardHeader,
	CardFooter,
	Table,
	Container,
	Row,
	Breadcrumb,
	BreadcrumbItem,
} from "reactstrap"
// core components
import { Component } from "react"
import { Link } from "react-router-dom"
import { connect } from "react-redux"
import { account } from "../../../redux/actions"
import ReactPaginate from "react-paginate"

class Account extends Component {
	constructor(props) {
		super(props)

		this.state = {
			params: {
				limit: 10,
				page: 1,
			},
		}
	}

	handlePageClick(e) {
		const oldState = { ...this.state }

		oldState.params.page = e.selected + 1

		this.setState(oldState, () => {
			this.getAccounts()
		})
	}

	getAccounts() {
		this.props.getAccounts(this.state.params)
	}

	componentDidMount() {
		this.getAccounts()
	}
	render() {
		return (
			<>
				{/* Page content */}
				<Container fluid className='mb-5'>
					<div>
						<Breadcrumb>
							<BreadcrumbItem active>Manajemen Akun</BreadcrumbItem>
						</Breadcrumb>
					</div>
					{/* Table */}
					<Row>
						<div className='col'>
							<Card>
								<CardHeader className='d-flex'>
									<h3 className='mb-0 mr-auto'>Manajemen Akun</h3>
								</CardHeader>
								<CardHeader className='border-0 d-flex'>
									<Link
										to={{
											pathname: "/admin/account/create",
										}}
										className='btn btn-primary ml-auto'
									>
										Tambah Akun
									</Link>
								</CardHeader>
								<Table
									className='align-items-center table-flush table-striped'
									responsive
								>
									<thead className='thead-light'>
										<tr>
											<th scope='col'>No</th>
											<th scope='col'>Nama</th>
											<th scope='col'>Email</th>
											<th scope='col'>Type</th>
											<th scope='col'>Action</th>
										</tr>
									</thead>
									<tbody>
										{this.props.account.data.map((user, i) => (
											<tr>
												<td>
													{(this.props.account.page.currentPage - 1) *
														this.state.params.limit +
														i +
														1}
												</td>
												<td>{user.name}</td>
												<td>{user.email}</td>
												<td>{user.Role.name}</td>
												<td>
													<Link
														to={{
															pathname: `/admin/account/${user.id}`,
														}}
													>
														Lihat Detail
													</Link>
												</td>
											</tr>
										))}
									</tbody>
								</Table>
								<CardFooter className='py-4 d-flex justify-content-end align-items-center'>
									<p>
										{`Menampilkan ${this.props.account.data.length} dari ${this.props.account.page.totalData} data`}
									</p>
									<ReactPaginate
										breakLabel='...'
										nextLabel='&raquo;'
										onPageChange={this.handlePageClick.bind(this)}
										pageRangeDisplayed={1}
										pageCount={this.props.account.page.totalPage}
										marginPagesDisplayed={1}
										previousLabel='&laquo;'
										renderOnZeroPageCount={null}
										className='pagination ml-3'
										pageClassName='page-item'
										pageLinkClassName='page-link'
										activeClassName='active'
										disabledClassName='disabled'
										nextClassName='page-item'
										breakClassName='page-item'
										nextLinkClassName='page-link'
										previousClassName='page-item'
										previousLinkClassName='page-link'
										breakLinkClassName='page-link'
									/>
								</CardFooter>
							</Card>
						</div>
					</Row>
				</Container>
			</>
		)
	}
}

const mapStateToProps = (state) => {
	return {
		account: state.account,
	}
}

const mapDispatchToProps = {
	getAccounts: account.getAccounts,
}

export default connect(mapStateToProps, mapDispatchToProps)(Account)
