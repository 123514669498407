import { axiosInstance } from "../../../helper"
import { banner } from "../types"

export function deleteInfoBanner(id, config) {
	return {
		type: banner.DELETE_INFO_BANNER,
		payload: new axiosInstance.delete(`/banner/${id}`, config),
	}
}

export default deleteInfoBanner
