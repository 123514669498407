export const sacrament = {
	GET_BAPTISM: "sacrament/baptism/get",
	GET_BAPTISM_DETAIL: "sacrament/baptism/detail",

	GET_MATRIMONY: "sacrament/matrimony/get",
	GET_MATRIMONY_DETAIL: "sacrament/matrimony/detail",

	GET_CHILD: "sacrament/child/get",
	GET_CHILD_DETAIL: "sacrament/child/detail",
}

export default sacrament
