import {
  Card,
  CardHeader,
  CardFooter,
  Table,
  Container,
  Row,
  BreadcrumbItem,
  Breadcrumb,
  Input,
} from "reactstrap"
// core components
import { Component } from "react"
import { Link } from "react-router-dom"
import ReactPaginate from "react-paginate"
import { connect } from "react-redux"
import { resource } from "../../../redux/actions"

class Resource extends Component {
  constructor(props) {
    super(props)

    this.state = {
      params: {
        limit: 10,
        page: 1,
      },
    }
  }

  handlePageClick(e) {
    const oldState = { ...this.state }

    oldState.params.page = e.selected + 1

    this.setState(oldState, () => {
      this.getResources()
    })
  }

  getResources() {
    this.props.getResources(this.state.params)
  }

  componentDidMount() {
    this.getResources()
  }
  render() {
    return (
      <>
        {/* Page content */}
        <Container fluid className="mb-5">
          <div>
            <Breadcrumb>
              <BreadcrumbItem active>Resources</BreadcrumbItem>
            </Breadcrumb>
          </div>
          {/* Table */}
          <Row>
            <div className="col">
              <Card>
                <CardHeader className="d-flex">
                  <h3 className="mr-auto">Resources</h3>
                </CardHeader>
                <CardHeader className="d-flex">
                  <div className="col-xl-4">
                    {/* <span className='fa fa-search form-control-feedback'></span> */}
                    <Input
                      type="text"
                      className="form-control form-search"
                      placeholder="Cari berdasarkan nama"
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          this.getResources()
                        }
                      }}
                      onChange={(e) => {
                        this.setState({
                          params: Object.assign({}, this.state.params, {
                            search: e.target.value,
                          }),
                        })
                      }}
                    />
                  </div>
                  <Link
                    to={{
                      pathname: "/admin/resource/create",
                    }}
                    className="btn btn-primary ml-auto"
                  >
                    Tambah Resource
                  </Link>
                </CardHeader>
                <Table
                  className="align-items-center table-flush table-striped"
                  responsive
                >
                  <thead className="thead-light">
                    <tr>
                      <th scope="col">No.</th>
                      <th scope="col">Gambar</th>
                      <th scope="col">Nama Buku</th>
                      <th scope="col">Harga (IDR)</th>
                      <th scope="col">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.props.resource.data.map((resource, i) => (
                      <tr>
                        <td>
                          {(this.props.resource.page.currentPage - 1) *
                            this.state.params.limit +
                            i +
                            1}
                        </td>
                        <td>
                          <div className="d-flex justify-content-center">
                            <div
                              className="img-rounded-item"
                              style={{
                                backgroundImage: `url("${resource.image}")`,
                              }}
                            ></div>
                          </div>
                        </td>
                        <td>{resource.title}</td>
                        <td>{resource.price}</td>
                        <td>
                          <Link
                            to={{
                              pathname: `/admin/resource/${resource.id}`,
                            }}
                          >
                            Lihat Detail
                          </Link>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
                <CardFooter className="py-4 d-flex justify-content-end align-items-center">
                  <p>
                    {`Menampilkan ${this.props.resource.data.length} dari ${this.props.resource.page.totalData} data`}
                  </p>
                  <ReactPaginate
                    breakLabel="..."
                    nextLabel="&raquo;"
                    onPageChange={this.handlePageClick.bind(this)}
                    pageRangeDisplayed={1}
                    pageCount={this.props.resource.page.totalPage}
                    marginPagesDisplayed={1}
                    previousLabel="&laquo;"
                    renderOnZeroPageCount={null}
                    className="pagination ml-3"
                    pageClassName="page-item"
                    pageLinkClassName="page-link"
                    activeClassName="active"
                    disabledClassName="disabled"
                    nextClassName="page-item"
                    breakClassName="page-item"
                    nextLinkClassName="page-link"
                    previousClassName="page-item"
                    previousLinkClassName="page-link"
                    breakLinkClassName="page-link"
                  />
                </CardFooter>
              </Card>
            </div>
          </Row>
        </Container>
      </>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    resource: state.resource,
  }
}

const mapDispatchToProps = {
  getResources: resource.getResources,
}

export default connect(mapStateToProps, mapDispatchToProps)(Resource)
