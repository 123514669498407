import { axiosInstance } from "../../../helper"
import { resource } from "../types"

export function getResources(params, config) {
	return {
		type: resource.GET_RESOURCES,
		payload: new axiosInstance.get(`/resource/`, {
			...config,
			params,
		}),
	}
}

export default getResources
