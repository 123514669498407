import { axiosInstance } from "../../../helper"
import { sacrament } from "../types"

export function getChildSubmissionDetail(id, params, config) {
	return {
		type: sacrament.GET_CHILD_DETAIL,
		payload: new axiosInstance.get(`/admin/sacrament/child-submission/${id}`, {
			...config,
			params,
		}),
	}
}

export default getChildSubmissionDetail
