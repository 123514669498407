import { axiosInstance } from "../../../helper"
import { video } from "../types"

export function getSpecialGuest(config) {
	return {
		type: video.GET_SPECIAL_GUEST,
		payload: new axiosInstance.get("/admin/streaming-video", {
			params: {
				type: 2,
			},
			...config,
		}),
	}
}

export default getSpecialGuest
