import { axiosInstance } from "../../../helper"
import { account } from "../types"

export function addAccount(body, config) {
	return {
		type: account.ADD,
		payload: new axiosInstance.post(`/admin/user/`, body, config),
	}
}

export default addAccount
