export const getGender = (status) => {
	switch (status) {
		case 1:
			return "Laki-laki"
		case 2:
			return "Perempuan"
		default:
			return "Tidak Diketahui/Lainnya"
	}
}

export default getGender
