export const account = {
	GET_ACCOUNT: "account/get",
	GET_PROFILE: "account/profile",
	GET_DETAIL_ACCOUNT: "account/detail",
	ADD: "account/add",
	DELETE: "account/delete",
	UPDATE: "account/update",
}

export default account
