import { axiosInstance } from "../../../helper"
import { banner } from "../types"

export function getInfoBanner(params, config) {
	return {
		type: banner.GET_BANNER,
		payload: new axiosInstance.get("/banner", {
			...config,
			params,
		}),
	}
}

export default getInfoBanner
