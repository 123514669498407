import getResources from "./getResources"
import getResource from "./getResource"
import addResource from "./addResource"
import updateResource from "./updateResource"
import deleteResource from "./deleteResource"

export {
	getResources,
	getResource,
	addResource,
	updateResource,
	deleteResource,
}

const resource = {
	getResources,
	getResource,
	addResource,
	updateResource,
	deleteResource,
}

export default resource
