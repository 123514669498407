import { Component } from "react"
import {
  Nav,
  NavItem,
  NavLink,
  Row,
  Card,
  Container,
  Breadcrumb,
  BreadcrumbItem,
  CardHeader,
  Input,
  Table,
} from "reactstrap"

import { Link } from "react-router-dom"
import { secretariat } from "../../../redux/actions"
import { connect } from "react-redux"

class Secretariat extends Component {
  constructor() {
    super()

    this.state = {
      activeTab: null,
    }
  }

  handlePageClick() {}

  getSecretariat() {
    return this.props.getSecretariat()
  }

  getSecretariatDetail(id) {
    return this.props.getSecretariatDetail(id)
  }

  componentDidMount() {
    this.getSecretariat().then(() => {
      if (this.props.secretariat.data.length) {
        this.getSecretariatDetail(this.props.secretariat.data[0].id).then(
          () => {
            this.setState({
              activeTab: this.props.secretariat.data[0].id,
            })
          }
        )
      }
    })
  }

  render() {
    return (
      <Container className="mb-5" fluid>
        <div>
          <Breadcrumb>
            <BreadcrumbItem active>Secretariat</BreadcrumbItem>
          </Breadcrumb>
        </div>
        <Nav tabs>
          {this.props.secretariat.data.map((secretariat) => (
            <NavItem>
              <NavLink
                active={this.state.activeTab === secretariat.id}
                onClick={() => {
                  this.getSecretariatDetail(secretariat.id).then(() => {
                    this.setState({
                      activeTab: secretariat.id,
                    })
                  })
                }}
              >
                {secretariat.name}
              </NavLink>
            </NavItem>
          ))}
        </Nav>

        <Row>
          <div className="col">
            {this.props.secretariat.detail.name && (
              <Card
                style={{
                  borderTop: "0",
                }}
              >
                <CardHeader className="d-flex">
                  <h3 className="mr-auto">
                    {this.props.secretariat.detail.name}
                  </h3>
                </CardHeader>
                <CardHeader className="d-flex">
                  <div className="col-xl-4">
                    {/* <span className='fa fa-search form-control-feedback'></span> */}
                    <Input
                      type="text"
                      className="form-control form-search"
                      placeholder="Cari berdasarkan nama"
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          this.getChurches()
                        }
                      }}
                      onChange={(e) => {
                        this.setState({
                          params: Object.assign({}, this.state.params, {
                            search: e.target.value,
                          }),
                        })
                      }}
                    />
                  </div>

                  <Link
                    to={{
                      pathname: "/admin/secretariat/create",
                    }}
                    className="btn btn-primary ml-auto"
                  >
                    Tambah Sekretariat
                  </Link>
                </CardHeader>
                <Table
                  className="align-items-center table-flush table-striped"
                  responsive
                >
                  <thead className="thead-light">
                    <tr>
                      <th scope="col">No.</th>
                      <th scope="col">Gambar</th>
                      <th scope="col">Nama</th>
                      <th scope="col">Jabatan</th>
                      <th scope="col">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.props.secretariat.detail.SecretariatItems.map(
                      (item, i) => (
                        <tr>
                          <td>{i + 1}</td>
                          <td>
                            <div className="d-flex justify-content-center">
                              <div
                                className="img-rounded-item"
                                style={{
                                  backgroundImage: `url("${item.image}")`,
                                }}
                              ></div>
                            </div>
                          </td>
                          <td>{item.name}</td>
                          <td>{item.position}</td>
                          <td>
                            <Link
                              to={{
                                pathname: `/admin/secretariat/${item.id}`,
                              }}
                            >
                              Lihat Detail
                            </Link>
                          </td>
                        </tr>
                      )
                    )}
                  </tbody>
                </Table>
              </Card>
            )}
          </div>
        </Row>
      </Container>
    )
  }
}
const mapStateToProps = (state) => {
  return {
    secretariat: state.secretariat,
  }
}

const mapDispatchToProps = {
  getSecretariat: secretariat.getSecretariat,
  getSecretariatDetail: secretariat.getSecretariatDetail,
}

export default connect(mapStateToProps, mapDispatchToProps)(Secretariat)
