import { axiosInstance } from "../../../helper"
import { video } from "../types"

export function changeTypeVideo(id, data, config) {
	return {
		type: video.CHANGE_TYPE_VIDEO,
		payload: new axiosInstance.put(
			`/admin/streaming-video/${id}/change-type`,
			data,
			config
		),
	}
}

export default changeTypeVideo
