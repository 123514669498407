import {
	Card,
	CardHeader,
	CardBody,
	Container,
	Row,
	Col,
	Form,
	FormGroup,
	Input,
	Label,
	Button,
	Breadcrumb,
	BreadcrumbItem,
} from "reactstrap"
// core components
import { Component } from "react"
import Swal from "sweetalert2"
import { toast } from "react-toastify"
import { connect } from "react-redux"
import { role, account } from "../../../redux/actions"
import { Link } from "react-router-dom"

class CreateAccount extends Component {
	constructor(props) {
		super(props)

		this.state = {
			name: "",
			email: "",
			password: "",
			role_id: "",
			readOnly: this.props.match.params.userId !== "create",
		}
	}

	modalToggle() {
		this.setState({
			isModalShow: !this.state.isModalShow,
		})
	}

	goBack() {
		if (this.props.match.params.userId === "create") {
			this.props.history.push("/admin/account")
		}

		return this.setState({
			readOnly: !this.state.readOnly,
		})
	}

	addAccount() {
		this.props
			.addAccount({
				name: this.state.name,
				email: this.state.email,
				password: this.state.password ? this.state.password : undefined,
				role_id: Number(this.state.role_id),
			})
			.then(() => {
				toast.success("Berhasil Tambah Account")
				this.props.history.push("/admin/account")
			}).catch((err) => {
				toast.error(err.response ? err.response.data.message : err.message)
			})
	}

	updateAccount() {
		this.props
			.updateAccount(this.props.match.params.userId, {
				name: this.state.name,
				email: this.state.email,
				password: this.state.password ? this.state.password : undefined,
				role_id: Number(this.state.role_id),
			})
			.then(() => {
				toast.success("Berhasil Update Account")
				this.props.history.push("/admin/account")
			}).catch(err => {
				toast.error(err.response ? err.response.data.message : err.message)
			})
	}

	getRoles() {
		this.props.getRoles({
			hides: [1],
		})
	}

	getAccount() {
		this.props.getAccount(this.props.match.params.userId).then(() => {
			const detail = this.props.account.detail
			this.setState({
				name: detail.name,
				email: detail.email,
				password: detail.password,
				role_id: detail.role_id,
			})
		})
	}

	handleDelete() {
		Swal.fire({
			title: "Are you sure?",
			text: "You won't be able to revert this!",
			icon: "warning",
			showCancelButton: true,
			confirmButtonColor: "#3085d6",
			cancelButtonColor: "#d33",
			confirmButtonText: "Yes, delete it!",
		}).then((result) => {
			if (result.isConfirmed) {
				this.props.deleteAccount(this.props.match.params.userId).then(() => {
					Swal.fire("Deleted!", "Account has been deleted.", "success")
					this.props.history.push(`/admin/account`)
				})
			}
		})
	}

	componentDidMount() {
		this.getRoles()
		if (this.state.readOnly) {
			this.getAccount()
		}
	}

	proxySubmit(e) {
		e.preventDefault()

		if (this.props.match.params.userId === "create") {
			return this.addAccount()
		}

		return this.updateAccount()
	}
	render() {
		return (
			<>
				{/* Page content */}
				<Container fluid className='mb-5'>
					<div>
						<Breadcrumb>
							<BreadcrumbItem>
								<Link to='/admin/account'>Manajemen Akun</Link>
							</BreadcrumbItem>

							<BreadcrumbItem>Tambah Akun</BreadcrumbItem>
						</Breadcrumb>
					</div>
					{/* Table */}
					<Row>
						<Col xl={8}>
							<Card>
								<CardHeader>
									<h3 className='mb-0'>Detail Akun</h3>
								</CardHeader>
								<CardBody>
									<Form
										onSubmit={this.proxySubmit.bind(this)}
										id="create-account"
									>
										<FormGroup>
											<Label for='name'>Nama</Label>
											<Input
												type='text'
												name='name'
												id='name'
												onChange={(e) => {
													this.setState({
														name: e.target.value,
													})
												}}
												value={this.state.name}
												readOnly={this.state.readOnly}
											/>
										</FormGroup>
										<FormGroup>
											<Label for='email'>Email</Label>
											<Input
												type='text'
												name='email'
												id='email'
												onChange={(e) => {
													this.setState({
														email: e.target.value,
													})
												}}
												value={this.state.email}
												readOnly={this.state.readOnly}
											/>
										</FormGroup>

										<FormGroup>
											<Label for='password'>Password</Label>
											<Input
												type='password'
												name='password'
												id='password'
												onChange={(e) => {
													this.setState({
														password: e.target.value,
													})
												}}
												value={this.state.password}
												readOnly={this.state.readOnly}
											/>
										</FormGroup>

										<FormGroup>
											<Label for='type'>Tipe Akun</Label>
											<Input
												id='type'
												name='type'
												type='select'
												readOnly={this.state.readOnly}
												value={this.state.role_id}
												onChange={(e) => {
													if (!this.state.readOnly) {
														this.setState({
															role_id: e.target.value,
														})
													}
												}}
											>
												<option value='' selected disabled>
													Pilih Tipe
												</option>
												{this.props.role.data.map((role) => (
													<option value={role.id}>{role.name}</option>
												))}
											</Input>
										</FormGroup>
									</Form>
									<Container className='d-flex'>
										<div className='d-flex flex-row align-items-center mb-3 ml-auto'>
											{this.state.readOnly ? (
												<>
													<Button
														color='primary'
														outline
														onClick={this.handleDelete.bind(this)}
													>
														Hapus
													</Button>
													<div>
														<Button
															color='primary'
															type='button'
															onClick={() => {
																this.setState({
																	readOnly: !this.state.readOnly,
																})
															}}
														form='create-account'
														>
															Edit Account
														</Button>
													</div>
												</>
											) : (
												<>
													<Button
														color='primary'
														outline
														onClick={this.goBack.bind(this)}
													>
														Batal
													</Button>
													<Button
														color='primary'
														type='submit'
														form='create-account'
													>
														Simpan
													</Button>
												</>
											)}
										</div>
									</Container>
								</CardBody>
							</Card>
						</Col>
					</Row>
				</Container>
			</>
		)
	}
}

const mapStateToProps = (state) => {
	return {
		role: state.role,
		account: state.account,
	}
}

const mapDispatchToProps = {
	getRoles: role.getRoles,
	addAccount: account.addAccount,
	getAccount: account.getAccount,
	deleteAccount: account.deleteAccount,
	updateAccount: account.updateAccount
}
export default connect(mapStateToProps, mapDispatchToProps)(CreateAccount)
