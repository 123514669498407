import { auth } from "../types"
import axiosInstance from "../../../helper/axiosInstance"

function login(data, config) {
	const body = {
		email: data.email,
		password: data.password,
	}

	return {
		type: auth.LOGIN,
		payload: axiosInstance.post("/auth/login", body, config),
	}
}

export default login
