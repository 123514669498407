import { axiosInstance } from "../../../helper"
import { ministry } from "../types"

export function updateMinistryContent(id, body, config) {
	return {
		type: ministry.UPDATE_MINISTRY_CONTENT,
		payload: new axiosInstance.post(
			`/admin/ministry/${id}/content`,
			body,
			config
		),
	}
}

export default updateMinistryContent
