import { axiosInstance } from "../../../helper"
import { banner } from "../types"

export function addInfoBanner(body, config) {
	return {
		type: banner.ADD_INFO_BANNER,
		payload: new axiosInstance.post(`/banner`, body, config),
	}
}

export default addInfoBanner
