import { Component } from "react"

import { Card, BreadcrumbItem, Breadcrumb, Container } from "reactstrap"
import Slider from "react-slick"

import { Icon } from "@iconify/react"

import React from "react"
import { connect } from "react-redux"
import { banner } from "../../../redux/actions"
import { Link } from "react-router-dom"
import { toast } from "react-toastify"
import Swal from "sweetalert2"

class Banner extends Component {
	getEventBanner() {
		this.props.getEventBanner()
	}

	getInfoBanner() {
		this.props.getInfoBanner()
	}

	deleteInfoBanner(id) {
		this.props.deleteInfoBanner(id).then(() => {
			toast.success("Berhasil Hapus Banner")
			this.getInfoBanner()
		})
	}

	deleteEventBanner(id) {
		this.props.deleteEventBanner(id).then(() => {
			toast.success("Berhasil Hapus Banner")
			this.getEventBanner()
		})
	}

	handleDelete(id, type) {
		Swal.fire({
			title: "Are you sure?",
			text: "You won't be able to revert this!",
			icon: "warning",
			showCancelButton: true,
			confirmButtonColor: "#3085d6",
			cancelButtonColor: "#d33",
			confirmButtonText: "Yes, delete it!",
		}).then((result) => {
			if (result.isConfirmed) {
				if (type === "event") {
					return this.deleteEventBanner(id)
				}

				if (type === "info") {
					return this.deleteInfoBanner(id)
				}
			}
		})
	}

	componentDidMount() {
		this.getEventBanner()
		this.getInfoBanner()
	}

	render() {
		return (
			<>
				<Container className='mb-5' fluid>
					<div>
						<Breadcrumb>
							<BreadcrumbItem active>Banner & Informasi</BreadcrumbItem>
						</Breadcrumb>
					</div>
					<div className='d-flex justify-content-end'>
						{/* Page content */}
						<Link to='/admin/banner/create' className='btn btn-primary'>
							Tambah Banner & Informasi
						</Link>
					</div>
					<h1>Banner Event</h1>
					<Slider
						dots
						speed={500}
						slidesToShow={
							this.props.banner.eventData.length > 3
								? 3
								: this.props.banner.eventData.length
						}
						slidesToScroll={3}
						className='mb-3'
						draggable
					>
						{this.props.banner.eventData.map((event) => {
							return (
								<Container>
									<Card className='mb-4 mb-xl-0 banner mt-3'>
										<img src={event.image} alt='' />
										<div
											className='del-card'
											onClick={() => {
												this.handleDelete(event.id, "event")
											}}
										>
											<Icon icon='akar-icons:circle-x-fill' fontSize={"35px"} />
										</div>
									</Card>
								</Container>
							)
						})}
					</Slider>

					<h1>Banner Informasi</h1>
					<Slider
						dots
						speed={500}
						slidesToShow={
							this.props.banner.infoData.length > 3
								? 3
								: this.props.banner.infoData.length
						}
						slidesToScroll={3}
						className='mb-3'
					>
						{this.props.banner.infoData.map((info) => {
							return (
								<Container>
									<Card className='mb-4 mb-xl-0 banner mt-3'>
										<img src={info.image} alt='' />
										<div
											className='del-card'
											onClick={() => {
												this.handleDelete(info.id, "info")
											}}
										>
											<Icon icon='akar-icons:circle-x-fill' fontSize={"35px"} />
										</div>
									</Card>
								</Container>
							)
						})}
					</Slider>
				</Container>
			</>
		)
	}
}

const mapStateToProps = (state) => {
	return {
		banner: state.banner,
	}
}

const mapDispatchToProps = {
	getEventBanner: banner.getEventBanner,
	getInfoBanner: banner.getInfoBanner,
	deleteEventBanner: banner.deleteEventBanner,
	deleteInfoBanner: banner.deleteInfoBanner,
}

export default connect(mapStateToProps, mapDispatchToProps)(Banner)
