import { axiosInstance } from "../../../helper"
import { church } from "../types"

export function nextChurches(params, config) {
	return {
		type: church.NEXT_CHURCHES,
		payload: new axiosInstance.get("/church", {
			...config,
			params,
		}),
	}
}

export default nextChurches
