import { promiseState } from "../../helper"
import { worship } from "../../redux/actions/types"

const { pendingState, fulfilledState, rejectedState } = promiseState

const initialState = {
	isLoading: false,
	errors: [],
	message: "",
	data: {
		EventDateTimes: [],
		date: "",
	},
}

function worshipReducer(state = initialState, action) {
	switch (action.type) {
		case pendingState(worship.GET_WORSHIP): {
			return Object.assign({}, state, {
				isLoading: true,
			})
		}

		case rejectedState(worship.GET_WORSHIP): {
			return Object.assign({}, state, {
				isLoading: false,
			})
		}

		case fulfilledState(worship.GET_WORSHIP): {
			return Object.assign({}, state, {
				isLoading: false,
				data: action.payload.data.data,
			})
		}

		default:
			return state
	}
}

export default worshipReducer
