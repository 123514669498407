import {
	Container,
	Breadcrumb,
	BreadcrumbItem,
	Col,
	CardBody,
	Row,
	Card,
	CardImg,
} from "reactstrap"
// core components
import { Component } from "react"

import { Link } from "react-router-dom"

class Sacrament extends Component {
	render() {
		return (
			<Container fluid className='mb-5'>
				<div>
					<Breadcrumb>
						<BreadcrumbItem active>Sakramen</BreadcrumbItem>
					</Breadcrumb>
				</div>

				<Row>
					<Col xl={`2c4`}>
						<Link to='/admin/sacrament/baptism'>
							<Card className='mb-3'>
								<CardImg
									top
									src={require("../../../assets/img/baptisan.png").default}
								/>
								<CardBody>
									<h4>Baptisan</h4>
								</CardBody>
							</Card>
						</Link>
					</Col>
					<Col xl={`2c4`}>
						<Link to='/admin/sacrament/holy-matrimony'>
							<Card className='mb-3'>
								<CardImg
									top
									src={require("../../../assets/img/matrimony.png").default}
								/>
								<CardBody>
									<h4>Pemberkatan Nikah</h4>
								</CardBody>
							</Card>
						</Link>
					</Col>
					<Col xl={`2c4`}>
						<Link to='/admin/sacrament/child-submission'>
							<Card className='mb-3'>
								<CardImg
									top
									src={require("../../../assets/img/child.png").default}
								/>
								<CardBody>
									<h4>Penyerahan Anak</h4>
								</CardBody>
							</Card>
						</Link>
					</Col>
				</Row>
			</Container>
		)
	}
}

export default Sacrament
