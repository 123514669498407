import getSecretariat from "./getSecretariat"
import getSecretariatDetail from "./getSecretariatDetail"
import addItem from "./addItem"
import deleteItem from "./deleteItem"
import getItemDetail from "./getItemDetail"
import updateItem from "./updateItem"

export {
	getSecretariat,
	getSecretariatDetail,
	addItem,
	deleteItem,
	updateItem,
	getItemDetail,
}

const secretariat = {
	getSecretariat,
	getSecretariatDetail,
	addItem,
	deleteItem,
	updateItem,
	getItemDetail,
}

export default secretariat
