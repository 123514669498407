function getColorPercent(value, total) {
	const result = (value / total) * 100

	if (result >= 0 && result <= 70) {
		return "#1ECF30"
	} else if (result > 70 && result < 90) {
		return "#FFAD33"
	} else {
		return "#FF3D00"
	}
}

export default getColorPercent
