import { churchRecord } from "../types"
import axiosInstance from "../../../helper/axiosInstance"

export function getRecords(params, config) {
	return {
		type: churchRecord.GET_RECORD,
		payload: new axiosInstance.get("/admin/church-record", {
			params,
			...config,
		}),
	}
}

export default getRecords
