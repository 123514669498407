import { axiosInstance } from "../../../helper"
import { church } from "../types"

export function getChurch(id, params, config) {
	return {
		type: church.GET_DETAIL_CHURCH,
		payload: new axiosInstance.get(`/church/${id}`, {
			...config,
			params,
		}),
	}
}

export default getChurch
