import { axiosInstance } from "../../../helper"
import { magazine } from "../types"

export function getLastMagazine(params, config) {
	return {
		type: magazine.GET_LAST_MAGAZINE,
		payload: new axiosInstance.get(`/weekly-magazine`, {
			...config,
			params,
		}),
	}
}

export default getLastMagazine
