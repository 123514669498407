import { axiosInstance } from "../../../helper"
import { secretariat } from "../types"

export function deleteItem(id, config) {
	return {
		type: secretariat.DELETE_ITEM,
		payload: new axiosInstance.delete(`/admin/secretariat-item/${id}`, config),
	}
}

export default deleteItem
