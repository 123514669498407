import { axiosInstance } from "../../../helper"
import { secretariat } from "../types"

export function addItem(body, config) {
	return {
		type: secretariat.ADD_ITEM,
		payload: new axiosInstance.post(`/admin/secretariat-item/`, body, config),
	}
}

export default addItem
