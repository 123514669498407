import { account } from "../types"
import axiosInstance from "../../../helper/axiosInstance"

export function getAccounts(params, config) {
	return {
		type: account.GET_ACCOUNT,
		payload: new axiosInstance.get("/admin/user", {
			params,
			...config,
		}),
	}
}

export default getAccounts
