import { axiosInstance } from "../../../helper"
import { user } from "../types"

export function getUser(id, params, config) {
	return {
		type: user.GET_DETAIL_USER,
		payload: new axiosInstance.get(`/user/${id}`, {
			...config,
			params,
		}),
	}
}

export default getUser
