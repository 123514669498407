import { axiosInstance } from "../../../helper"
import { banner } from "../types"

export function addEventBanner(body, config) {
	return {
		type: banner.ADD_EVENT_BANNER,
		payload: new axiosInstance.post(`/event-banner`, body, config),
	}
}

export default addEventBanner
