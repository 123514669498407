import { axiosInstance } from "../../../helper"
import { banner } from "../types"

export function getEventBanner(params, config) {
	return {
		type: banner.GET_EVENT_BANNER,
		payload: new axiosInstance.get("/event-banner", {
			...config,
			params,
		}),
	}
}

export default getEventBanner
