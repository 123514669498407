import { axiosInstance } from "../../../helper"
import { role } from "../types"

export function getRoles(params, config) {
	return {
		type: role.GET,
		payload: new axiosInstance.get("/admin/role", {
			...config,
			params,
		}),
	}
}

export default getRoles
