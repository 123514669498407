import { axiosInstance } from "../../../helper"
import { worshipTime } from "../types"

export function updateWorshipTime(id, data, config) {
	return {
		type: worshipTime.UPDATE_WORSHIP_TIME,
		payload: new axiosInstance.put(`/admin/schedule-time/${id}`, data, config),
	}
}

export default updateWorshipTime
