import { axiosInstance } from "../../../helper"
import { resource } from "../types"

export function addResource(body, config) {
	return {
		type: resource.ADD_RESOURCE,
		payload: new axiosInstance.post(`/admin/resource/`, body, config),
	}
}

export default addResource
