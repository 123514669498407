import { axiosInstance } from "../../../helper"
import { worshipTime } from "../types"

export function getWorshipTime(id, params, config) {
	return {
		type: worshipTime.GET_WORSHIP_TIME,
		payload: new axiosInstance.get(`/admin/schedule-time/${id}`, {
			...config,
			params,
		}),
	}
}

export default getWorshipTime
