export const getLastEducation = (status) => {
	switch (status) {
		case 1:
			return "Tidak/Belum Sekolah"
		case 2:
			return "Tidak Tamat SD/Sederajat"
		case 3:
			return "Tamat SD/Sederajat"

		case 4:
			return "SLTP/Sederajat"
		case 5:
			return "SLTA/Sederajat"
		case 6:
			return "Diploma I/II"
		case 7:
			return "Akademi/Diploma III/Sarjana Muda"
		case 8:
			return "Diploma IV/Strata I"
		case 9:
			return "Strata II"
		case 10:
			return "Strata III"
		default:
			return "Lainnya"
	}
}

export default getLastEducation
