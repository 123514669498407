import { axiosInstance } from "../../../helper"
import { sacrament } from "../types"

export function getChildSubmissions(params, config) {
	return {
		type: sacrament.GET_CHILD,
		payload: new axiosInstance.get("/admin/sacrament/child-submission", {
			...config,
			params,
		}),
	}
}

export default getChildSubmissions
