import {
	Card,
	CardBody,
	Container,
	Row,
	Col,
	Form,
	FormGroup,
	Input,
	Label,
	Button,
	Breadcrumb,
	BreadcrumbItem,
	FormFeedback,
} from "reactstrap"
// core components
import { Component } from "react"
import { Link } from "react-router-dom"
import Joi from "joi"
import { connect } from "react-redux"
import { worship, worshipTime, church } from "../../../redux/actions"
import Swal from "sweetalert2"
import { toast } from "react-toastify"

class CreateWorshipSchedule extends Component {
	constructor(props) {
		super(props)

		this.state = {
			date: {
				value: "",
				rule: Joi.string().required(),
				valid: true,
				errMsg: "",
			},

			time: {
				value: null,
				rule: Joi.string().required(),
				valid: true,
				errMsg: "",
			},

			churchId: this.props.match.params.churchId,
			timeId: this.props.match.params.timeId,

			disable: this.props.match.params.timeId !== "create",
		}
	}

	inputOnChange(name, value) {
		const fieldProperties = { ...this.state }
		const isValid = fieldProperties[name].rule.validate(value)

		fieldProperties[name].valid = !isValid.error
		fieldProperties[name].value = value

		if (isValid.error) {
			fieldProperties[name].errMsg = isValid.error.message
		}

		this.setState(fieldProperties)
	}

	goBack() {
		if (this.state.timeId === "create") {
			return this.props.history.push(
				`/admin/schedule/worship/${this.state.churchId}`
			)
		}

		return this.setState({
			disable: !this.state.disable,
		})
	}

	getWorship() {
		this.props.getWorship({
			church_id: this.state.churchId,
		})
	}

	getWorshipTime() {
		this.props.getWorshipTime(this.state.timeId).then(() => {
			const fieldProperties = { ...this.state }
			fieldProperties.date.value = this.props.worshipTime.data.date
			fieldProperties.time.value = this.props.worshipTime.data.time

			this.setState(fieldProperties)
		})
	}

	getChurch() {
		this.props.getChurch(this.state.churchId)
	}

	componentDidMount() {
		this.getChurch()
		this.getWorship()
		if (this.state.timeId !== "create") {
			this.getWorshipTime()
		}

		if (this.props.location.state && this.props.location.state.date) {
			this.inputOnChange("date", this.props.location.state.date)
		}
	}

	addTime() {
		this.props
			.addWorshipTime({
				church_id: Number(this.state.churchId),
				time: this.state.time.value,
				date: this.state.date.value,
			})
			.then(() => {
				toast.success("Berhasil Tambah Schedule")
				this.props.history.push({
					pathname: `/admin/schedule/worship/${this.state.churchId}`,
					state: {
						date: this.state.date.value,
					},
				})
			})
	}

	editTime() {
		this.props
			.updateWorshipTime(this.props.match.params.timeId, {
				time: this.state.time.value,
			})
			.then(() => {
				toast.success("Berhasil Edit Schedule")
				this.getWorshipTime()

				this.setState({
					disable: true,
				})
			})
	}

	proxySubmit(e) {
		e.preventDefault()

		if (this.state.timeId === "create") {
			return this.addTime()
		}

		return this.editTime()
	}

	handleDelete() {
		Swal.fire({
			title: "Are you sure?",
			text: "You won't be able to revert this!",
			icon: "warning",
			showCancelButton: true,
			confirmButtonColor: "#3085d6",
			cancelButtonColor: "#d33",
			confirmButtonText: "Yes, delete it!",
		}).then((result) => {
			if (result.isConfirmed) {
				this.props
					.deleteWorshipTime(this.props.match.params.timeId)
					.then(() => {
						Swal.fire("Deleted!", "Your file has been deleted.", "success")
						this.props.history.push(
							`/admin/schedule/worship/${this.state.churchId}`
						)
					})
			}
		})
	}

	render() {
		return (
			<>
				{/* Page content */}
				<Container fluid className='mb-5'>
					<div>
						<Breadcrumb>
							<BreadcrumbItem>
								<Link to='/admin/schedule/worship'>Schedule</Link>
							</BreadcrumbItem>
							<BreadcrumbItem>
								<Link to='/admin/schedule/worship'>Ibadah Minggu</Link>
							</BreadcrumbItem>
							<BreadcrumbItem>
								<Link to={`/admin/schedule/worship/${this.state.churchId}`}>
									{this.props.church.name}
								</Link>
							</BreadcrumbItem>

							<BreadcrumbItem>Tambah Schedule</BreadcrumbItem>
						</Breadcrumb>
					</div>
					{/* Table */}
					<Row>
						<Col xl={7}>
							<Card>
								<CardBody>
									<h1 className='mb-4'>Tambah Schedule</h1>
									<Row>
										<Container>
											<Form
												onSubmit={this.proxySubmit.bind(this)}
												id='create-worship-schedule'
											>
												<FormGroup>
													<Label for='name'>Pilih Tanggal</Label>
													<Input
														type='date'
														name='date'
														id='date'
														onChange={(e) =>
															this.inputOnChange("date", e.target.value)
														}
														value={this.state.date.value}
														invalid={!this.state.date.valid}
														disabled={this.state.timeId !== "create"}
													/>
													<FormFeedback>{this.state.date.errMsg}</FormFeedback>
												</FormGroup>

												<FormGroup>
													<Label for='name'>Pilih Waktu</Label>
													<Input
														type='time'
														name='name'
														id='name'
														onChange={(e) =>
															this.inputOnChange("time", e.target.value)
														}
														invalid={!this.state.time.valid}
														disabled={this.state.disable}
														value={this.state.time.value}
													/>
													<FormFeedback>{this.state.time.errMsg}</FormFeedback>
												</FormGroup>
											</Form>
										</Container>
									</Row>
									<div className='d-flex justify-content-end'>
										{this.state.disable ? (
											<>
												<Button
													color='primary'
													outline
													onClick={this.handleDelete.bind(this)}
												>
													Hapus
												</Button>
												<div>
													<Button
														color='primary'
														type='button'
														onClick={() => {
															this.setState({
																disable: !this.state.disable,
															})
														}}
													>
														Edit Schedule
													</Button>
												</div>
											</>
										) : (
											<>
												<Button
													color='primary'
													outline
													onClick={this.goBack.bind(this)}
												>
													Batal
												</Button>
												<Button
													color='primary'
													type='submit'
													form='create-worship-schedule'
												>
													Simpan
												</Button>
											</>
										)}
									</div>
								</CardBody>
							</Card>
						</Col>
					</Row>
				</Container>
			</>
		)
	}
}

const mapStateToProps = (state) => {
	return {
		worship: state.worship,
		worshipTime: state.worshipTime,
		church: state.church.detail,
	}
}

const mapDispatchToProps = {
	getWorship: worship.getWorship,
	getWorshipTime: worshipTime.getWorshipTime,
	addWorshipTime: worshipTime.addWorshipTime,
	deleteWorshipTime: worshipTime.deleteWorshipTime,
	updateWorshipTime: worshipTime.updateWorshipTime,
	getChurch: church.getChurch,
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(CreateWorshipSchedule)
