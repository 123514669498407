export const event = {
	GET_EVENTS: "event/get",
	NEXT_EVENTS: "event/next",
	DETAIL_EVENT: "event/detail",
	ADD_EVENT: "event/add",
	UPDATE_EVENT: "event/update",
	DELETE: "event/delete",
}

export default event
