import { axiosInstance } from "../../../helper"
import { video } from "../types"

export function getVideoDetail(id, params, config) {
	return {
		type: video.GET_VIDEO_DETAIL,
		payload: new axiosInstance.get(`/admin/streaming-video/${id}`, {
			...config,
			params,
		}),
	}
}

export default getVideoDetail
