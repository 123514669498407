import { axiosInstance } from "../../../helper"
import { ministry } from "../types"

export function updateMinistry(id, data, config) {
	return {
		type: ministry.UPDATE_MINISTRY,
		payload: new axiosInstance.put(`/admin/ministry/${id}`, data, config),
	}
}

export default updateMinistry
