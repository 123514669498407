import {
	Card,
	CardBody,
	Container,
	Row,
	Col,
	Button,
	Breadcrumb,
	BreadcrumbItem,
	CardHeader,
} from "reactstrap"
// core components
import { Component } from "react"
import { Link } from "react-router-dom"

import { connect } from "react-redux"
import { churchRecord } from "../../../redux/actions"

import { getMaritalStatus, getLastEducation, getGender } from "../../../helper"
import moment from "moment"

class DetailRecord extends Component {
	getDetailRecord() {
		this.props.getDetailRecord(this.props.match.params.recordId)
	}
	componentDidMount() {
		this.getDetailRecord()
	}

	render() {
		return (
			<>
				{/* Page content */}
				<Container fluid className='mb-5'>
					<div>
						<Breadcrumb>
							<BreadcrumbItem>
								<Link to='/admin/church-record'>Pendataan Jemaat</Link>
							</BreadcrumbItem>

							<BreadcrumbItem active>Informasi Detail</BreadcrumbItem>
						</Breadcrumb>
					</div>
					{/* Table */}
					<Row>
						<Col xl={7}>
							<Card>
								<CardHeader>
									<h1>Informasi Detail</h1>
								</CardHeader>
								<CardBody>
									<Container>
										<table>
											<tr>
												<td>Nama</td>
												<td>{this.props.churchRecord.detail.name}</td>
											</tr>
											<tr>
												<td>Email</td>
												<td>{this.props.churchRecord.detail.email}</td>
											</tr>
											<tr>
												<td>No. Telp</td>
												<td>{this.props.churchRecord.detail.phone}</td>
											</tr>
											<tr>
												<td>Tempat Lahir</td>
												<td>{this.props.churchRecord.detail.birthplace}</td>
											</tr>
											<tr>
												<td>Tanggal Lahir</td>
												<td>
													{moment(
														this.props.churchRecord.detail.birthdate,
														"YYYY-MM-DD"
													)
														.locale("id")
														.format("DD MMM YYYY")}
												</td>
											</tr>
											<tr>
												<td>Alamat Lengkap</td>
												<td>{this.props.churchRecord.detail.address}</td>
											</tr>
											<tr>
												<td>Pendidikan</td>
												<td>
													{getLastEducation(
														this.props.churchRecord.detail.last_education
													)}
												</td>
											</tr>
											<tr>
												<td>Jenis Kelamin</td>
												<td>
													{getGender(this.props.churchRecord.detail.gender)}
												</td>
											</tr>
											<tr>
												<td>Status</td>
												<td>
													{getMaritalStatus(
														this.props.churchRecord.detail.marital_status
													)}
												</td>
											</tr>
											<tr>
												<td>Kota Domisili</td>
												<td>{this.props.churchRecord.detail.city}</td>
											</tr>
											<tr>
												<td>Cabang GBI Glow FC</td>
												<td>
													{this.props.churchRecord.detail.Church &&
														this.props.churchRecord.detail.Church.name}
												</td>
											</tr>
											<tr>
												<td>Apakah sudah dibaptis selam?</td>
												<td>
													{this.props.churchRecord.detail.is_baptized
														? "Sudah"
														: "Belum"}
												</td>
											</tr>
											<tr>
												<td> Ingin menjadi jemaat GBI GLOW FC?</td>
												<td>
													{this.props.churchRecord.detail.want_to_be_member
														? "Ya"
														: "Tidak"}
												</td>
											</tr>

											<tr>
												<td>Pas Photo</td>
												<td>
													<Link
														to={{
															pathname: this.props.churchRecord.detail.image,
														}}
														target='_blank'
													>
														Klik Disini
													</Link>
												</td>
											</tr>
										</table>

										<div className='d-flex justify-content-end'>
											<Button
												color='primary'
												onClick={() => {
													this.props.history.push("/admin/church-record")
												}}
											>
												Kembali
											</Button>
										</div>
									</Container>
								</CardBody>
							</Card>
						</Col>
					</Row>
				</Container>
			</>
		)
	}
}

const mapStateToProps = (state) => {
	return {
		churchRecord: state.churchRecord,
	}
}

const mapDispatchToProps = {
	getDetailRecord: churchRecord.getDetailRecord,
}

export default connect(mapStateToProps, mapDispatchToProps)(DetailRecord)
