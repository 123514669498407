import { axiosInstance } from "../../../helper"
import { video } from "../types"

export function getStreamingVideo(config) {
	return {
		type: video.GET_STREAMING_VIDEO,
		payload: new axiosInstance.get("/admin/streaming-video", {
			params: {
				type: 1,
			},
			...config,
		}),
	}
}

export default getStreamingVideo
