import {
  Card,
  CardHeader,
  CardFooter,
  Input,
  Table,
  Container,
  Row,
  Breadcrumb,
  BreadcrumbItem,
} from "reactstrap"
// core components
import { Component } from "react"
import { connect } from "react-redux"
import { user } from "../../../redux/actions"

import ReactPaginate from "react-paginate"
import { Link } from "react-router-dom"
import { getGender } from "../../../helper"
import moment from "moment"

class User extends Component {
  constructor(props) {
    super(props)

    this.state = {
      params: {
        limit: 10,
        page: 1,
      },
    }
  }

  getUsers() {
    this.props.getUsers(this.state.params)
  }

  componentDidMount() {
    this.getUsers()
  }

  handlePageClick(e) {
    const oldState = { ...this.state }

    oldState.params.page = e.selected + 1

    this.setState(oldState, () => {
      this.getUsers()
    })
  }

  render() {
    return (
      <>
        {/* Page content */}
        <Container fluid className="mb-5">
          <div>
            <Breadcrumb>
              <BreadcrumbItem active>User</BreadcrumbItem>
            </Breadcrumb>
          </div>
          {/* Table */}
          <Row>
            <div className="col">
              <Card>
                <CardHeader className="d-flex">
                  <h3 className="mb-0 mr-auto">Daftar Pengguna</h3>
                </CardHeader>

                <CardHeader className="border-0 d-flex">
                  <div className="col-xl-4">
                    {/* <span className="fa fa-search form-control-feedback"></span> */}
                    <Input
                      type="text"
                      className="form-control form-search"
                      placeholder="Cari berdasarkan nama"
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          this.getUsers()
                        }
                      }}
                      onChange={(e) => {
                        this.setState({
                          params: Object.assign({}, this.state.params, {
                            search: e.target.value,
                          }),
                        })
                      }}
                    />
                  </div>
                </CardHeader>
                <Table
                  className="align-items-center table-flush table-striped"
                  responsive
                >
                  <thead className="thead-light">
                    <tr>
                      <th scope="col">No.</th>
                      <th scope="col">Nama Lengkap</th>
                      <th scope="col">Umur</th>
                      <th scope="col">Gender</th>
                      <th scope="col">Metode Pendaftaran</th>
                      <th scope="col">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.props.user.data.map((user, i) => (
                      <tr>
                        <td>
                          {(this.props.user.page.currentPage - 1) *
                            this.state.params.limit +
                            i +
                            1}
                        </td>
                        <td>{user.name}</td>
                        <td>
                          {`${moment().diff(
                            moment(user.UserDetail.birthdate),
                            "years"
                          )} Tahun`}
                        </td>
                        <td>{getGender(user.UserDetail.gender)}</td>
                        <td
                          style={{
                            textTransform: "capitalize",
                          }}
                        >
                          {user.registered_by}
                        </td>

                        <td>
                          <Link to={`/admin/user/${user.id}`}>
                            Lihat Detail
                          </Link>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
                <CardFooter className="py-4 d-flex justify-content-end align-items-center">
                  <p>
                    Menampilkan {this.props.user.data.length} dari{" "}
                    {this.props.user.page.totalData} data
                  </p>
                  <ReactPaginate
                    breakLabel="..."
                    nextLabel="&raquo;"
                    onPageChange={this.handlePageClick.bind(this)}
                    pageRangeDisplayed={1}
                    pageCount={this.props.user.page.totalPage}
                    marginPagesDisplayed={1}
                    previousLabel="&laquo;"
                    renderOnZeroPageCount={null}
                    className="pagination ml-3"
                    pageClassName="page-item"
                    pageLinkClassName="page-link"
                    activeClassName="active"
                    disabledClassName="disabled"
                    nextClassName="page-item"
                    breakClassName="page-item"
                    nextLinkClassName="page-link"
                    previousClassName="page-item"
                    previousLinkClassName="page-link"
                    breakLinkClassName="page-link"
                  />
                </CardFooter>
              </Card>
            </div>
          </Row>
        </Container>
      </>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
  }
}

const mapDispatchToProps = {
  getUsers: user.getUsers,
}

export default connect(mapStateToProps, mapDispatchToProps)(User)
