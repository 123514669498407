import {
  Card,
  CardHeader,
  CardFooter,
  Table,
  Container,
  Row,
  BreadcrumbItem,
  Breadcrumb,
  Input,
} from "reactstrap"
// core components
import { Component } from "react"
import { Link } from "react-router-dom"
import ReactPaginate from "react-paginate"
import { connect } from "react-redux"
import { churchRecord } from "../../../redux/actions"

class ChurchRecord extends Component {
  constructor(props) {
    super(props)

    this.state = {
      params: {
        limit: 10,
        page: 1,
      },
    }
  }

  handlePageClick(e) {
    const oldState = { ...this.state }

    oldState.params.page = e.selected + 1

    this.setState(oldState, () => {
      this.getRecords()
    })
  }

  getRecords() {
    this.props.getRecords(this.state.params)
  }

  componentDidMount() {
    this.getRecords()
  }

  render() {
    return (
      <>
        {/* Page content */}
        <Container fluid className="mb-5">
          <div>
            <Breadcrumb>
              <BreadcrumbItem active>Pendataan Jemaat</BreadcrumbItem>
            </Breadcrumb>
          </div>
          {/* Table */}
          <Row>
            <div className="col">
              <Card>
                <CardHeader className="d-flex">
                  <h3 className="mr-auto">Pendataan Jemaat</h3>
                </CardHeader>
                <CardHeader>
                  <div className="row">
                    <div className="col-xl-4">
                      {/* <span className="fa fa-search form-control-feedback"></span> */}
                      <Input
                        type="text"
                        className="form-control form-search"
                        placeholder="Cari berdasarkan nama"
                        onKeyDown={(e) => {
                          if (e.key === "Enter") {
                            this.getRecords()
                          }
                        }}
                        onChange={(e) => {
                          this.setState({
                            params: Object.assign({}, this.state.params, {
                              search: e.target.value,
                            }),
                          })
                        }}
                      />
                    </div>
                  </div>
                </CardHeader>
                <Table
                  className="align-items-center table-flush table-striped"
                  responsive
                >
                  <thead className="thead-light">
                    <tr>
                      <th scope="col">No.</th>
                      <th scope="col">Nama</th>
                      <th scope="col">Email</th>
                      <th scope="col">No. Whatsapp</th>
                      <th scope="col">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.props.churchRecord.data.map((record, i) => (
                      <tr>
                        <td>
                          {(this.props.churchRecord.page.currentPage - 1) *
                            this.state.params.limit +
                            i +
                            1}
                        </td>
                        <td>{record.name}</td>
                        <td>{record.email}</td>
                        <td>{record.phone}</td>
                        <td>
                          <Link
                            to={{
                              pathname: `/admin/church-record/${record.id}`,
                            }}
                          >
                            Lihat Detail
                          </Link>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
                <CardFooter className="py-4 d-flex justify-content-end align-items-center">
                  <p>
                    {`Menampilkan ${this.props.churchRecord.data.length} dari ${this.props.churchRecord.page.totalData} data`}
                  </p>
                  <ReactPaginate
                    breakLabel="..."
                    nextLabel="&raquo;"
                    onPageChange={this.handlePageClick.bind(this)}
                    pageRangeDisplayed={1}
                    pageCount={this.props.churchRecord.page.totalPage}
                    marginPagesDisplayed={1}
                    previousLabel="&laquo;"
                    renderOnZeroPageCount={null}
                    className="pagination ml-3"
                    pageClassName="page-item"
                    pageLinkClassName="page-link"
                    activeClassName="active"
                    disabledClassName="disabled"
                    nextClassName="page-item"
                    breakClassName="page-item"
                    nextLinkClassName="page-link"
                    previousClassName="page-item"
                    previousLinkClassName="page-link"
                    breakLinkClassName="page-link"
                  />
                </CardFooter>
              </Card>
            </div>
          </Row>
        </Container>
      </>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    churchRecord: state.churchRecord,
  }
}

const mapDispatchToProps = {
  getRecords: churchRecord.getRecords,
}

export default connect(mapStateToProps, mapDispatchToProps)(ChurchRecord)
