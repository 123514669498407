import { axiosInstance } from "../../../helper"
import { sacrament } from "../types"

export function getBaptismDetail(id, params, config) {
	return {
		type: sacrament.GET_BAPTISM_DETAIL,
		payload: new axiosInstance.get(`/admin/sacrament/baptism/${id}`, {
			...config,
			params,
		}),
	}
}

export default getBaptismDetail
