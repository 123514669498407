import { axiosInstance } from "../../../helper"
import { ministry } from "../types"

export function deleteMinistry(id, config) {
	return {
		type: ministry.DELETE_MINISTRY,
		payload: new axiosInstance.delete(`/admin/ministry/${id}`, config),
	}
}

export default deleteMinistry
