import { axiosInstance } from "../../../helper"
import { worshipTime } from "../types"

export function addWorshipTime(body, config) {
	return {
		type: worshipTime.ADD_WORSHIP_TIME,
		payload: new axiosInstance.post(`/admin/schedule-time/`, body, config),
	}
}

export default addWorshipTime
