import { axiosInstance } from "../../../helper"
import { worshipTime } from "../types"

export function deleteWorshipTime(id, config) {
	return {
		type: worshipTime.DELETE_WORSHIP_TIME,
		payload: new axiosInstance.delete(`/admin/schedule-time/${id}`, config),
	}
}

export default deleteWorshipTime
