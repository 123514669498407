import getChurches from "./getChurches"
import nextChurches from "./nextChurches"
import getChurch from "./getChurch"
import addChurch from "./addChurch"
import updateChurch from "./updateChurch"
import deleteChurch from "./deleleChurch"

export {
	getChurches,
	nextChurches,
	getChurch,
	addChurch,
	updateChurch,
	deleteChurch,
}

const church = {
	getChurches,
	nextChurches,
	getChurch,
	addChurch,
	updateChurch,
	deleteChurch,
}

export default church
