import { axiosInstance } from "../../../helper"
import { secretariat } from "../types"

export function getSecretariat(params, config) {
	return {
		type: secretariat.GET_SECRETARIAT,
		payload: new axiosInstance.get("/admin/secretariat", {
			...config,
			params,
		}),
	}
}

export default getSecretariat
