import {
	Card,
	CardBody,
	Container,
	Row,
	Col,
	Button,
	Breadcrumb,
	BreadcrumbItem,
	CardHeader,
} from "reactstrap"
// core components
import { Component } from "react"
import { Link } from "react-router-dom"
import { connect } from "react-redux"
import { sacrament } from "../../../redux/actions"

class MatrimonialDetail extends Component {
	getMatrimonialDetail() {
		this.props.getMatrimonialDetail(this.props.match.params.matrimonyId)
	}
	componentDidMount() {
		this.getMatrimonialDetail()
	}

	render() {
		return (
			<>
				{/* Page content */}
				<Container fluid className='mb-5'>
					<div>
						<Breadcrumb>
							<BreadcrumbItem>
								<Link to='/admin/sacrament'>Sakramen</Link>
							</BreadcrumbItem>

							<BreadcrumbItem>
								<Link to='/admin/sacrament/h'>Peneguhan Nikah</Link>
							</BreadcrumbItem>

							<BreadcrumbItem active>Informasi Detail</BreadcrumbItem>
						</Breadcrumb>
					</div>
					{/* Table */}
					<Row>
						<Col xl={7}>
							<Card>
								<CardHeader>
									<h1>Informasi Detail</h1>
								</CardHeader>
								<CardBody>
									<Container>
										<h3>Calon Mempelai Pria</h3>
										<table>
											<tr>
												<td>Nama</td>
												<td>:</td>
												<td>
													{this.props.matrimony.detail.groom &&
														this.props.matrimony.detail.groom.name}
												</td>
											</tr>
											<tr>
												<td>Tanggal Lahir</td>
												<td>:</td>
												<td>
													{this.props.matrimony.detail.groom &&
														this.props.matrimony.detail.groom.birthdate}
												</td>
											</tr>
											<tr>
												<td>Tempat Lahir</td>
												<td>:</td>
												<td>
													{this.props.matrimony.detail.groom &&
														this.props.matrimony.detail.groom.birthplace}
												</td>
											</tr>
											<tr>
												<td>Nama Ayah</td>
												<td>:</td>
												<td>
													{this.props.matrimony.detail.groom &&
														this.props.matrimony.detail.groom.father_name}
												</td>
											</tr>

											<tr>
												<td>Nama Ibu</td>
												<td>:</td>
												<td>
													{this.props.matrimony.detail.groom &&
														this.props.matrimony.detail.groom.mother_name}
												</td>
											</tr>

											<tr>
												<td>Alamat Lengkap</td>
												<td>:</td>
												<td>
													{this.props.matrimony.detail.groom &&
														this.props.matrimony.detail.groom.address}
												</td>
											</tr>
											<tr>
												<td>No. Telp</td>
												<td>:</td>
												<td>
													{this.props.matrimony.detail.groom &&
														this.props.matrimony.detail.groom.phone}
												</td>
											</tr>
										</table>

										<h3>Calon Mempelai Wanita</h3>
										<table>
											<tr>
												<td>Nama</td>
												<td>:</td>
												<td>
													{this.props.matrimony.detail.bride &&
														this.props.matrimony.detail.bride.name}
												</td>
											</tr>
											<tr>
												<td>Tanggal Lahir</td>
												<td>:</td>
												<td>
													{this.props.matrimony.detail.bride &&
														this.props.matrimony.detail.bride.birthdate}
												</td>
											</tr>
											<tr>
												<td>Tempat Lahir</td>
												<td>:</td>
												<td>
													{this.props.matrimony.detail.bride &&
														this.props.matrimony.detail.bride.birthplace}
												</td>
											</tr>
											<tr>
												<td>Nama Ayah</td>
												<td>:</td>
												<td>
													{this.props.matrimony.detail.bride &&
														this.props.matrimony.detail.bride.father_name}
												</td>
											</tr>

											<tr>
												<td>Nama Ibu</td>
												<td>:</td>
												<td>
													{this.props.matrimony.detail.bride &&
														this.props.matrimony.detail.bride.mother_name}
												</td>
											</tr>

											<tr>
												<td>Alamat Lengkap</td>
												<td>:</td>
												<td>
													{this.props.matrimony.detail.bride &&
														this.props.matrimony.detail.bride.address}
												</td>
											</tr>
											<tr>
												<td>No. Telp</td>
												<td>:</td>
												<td>
													{this.props.matrimony.detail.bride &&
														this.props.matrimony.detail.bride.phone}
												</td>
											</tr>
										</table>

										<h3>Informasi Pernikahan</h3>
										<table>
											<tr>
												<td>Tanggal Pernikahan</td>
												<td>:</td>
												<td>{this.props.matrimony.detail.date}</td>
											</tr>
											<tr>
												<td>Tempat Pernikahan</td>
												<td>:</td>
												<td>{this.props.matrimony.detail.place}</td>
											</tr>
										</table>
										<h3>Dokumen</h3>
										<h3>Kartu Anggota Glow</h3>
										<table>
											<tr>
												<td>Pria</td>
												<td>:</td>
												<td>
													<a
														href={
															this.props.matrimony.detail.file &&
															this.props.matrimony.detail.file.groom_glow_member
														}
													>
														Lihat disini
													</a>
												</td>
											</tr>

											<tr>
												<td>Wanita</td>
												<td>:</td>
												<td>
													<a
														href={
															this.props.matrimony.detail.file &&
															this.props.matrimony.detail.file.bride_glow_member
														}
													>
														Lihat disini
													</a>
												</td>
											</tr>
										</table>

										<h3>Kartu Tanda Penduduk</h3>
										<table>
											<tr>
												<td>Pria</td>
												<td>:</td>
												<td>
													<a
														href={
															this.props.matrimony.detail.file &&
															this.props.matrimony.detail.file.groom_id_card
														}
													>
														Lihat disini
													</a>
												</td>
											</tr>

											<tr>
												<td>Wanita</td>
												<td>:</td>
												<td>
													<a
														href={
															this.props.matrimony.detail.file &&
															this.props.matrimony.detail.file.bride_id_card
														}
													>
														Lihat disini
													</a>
												</td>
											</tr>
										</table>

										<h3>Kartu Keluarga</h3>
										<table>
											<tr>
												<td>Pria</td>
												<td>:</td>
												<td>
													<a
														href={
															this.props.matrimony.detail.file &&
															this.props.matrimony.detail.file.groom_family_card
														}
													>
														Lihat disini
													</a>
												</td>
											</tr>

											<tr>
												<td>Wanita</td>
												<td>:</td>
												<td>
													<a
														href={
															this.props.matrimony.detail.file &&
															this.props.matrimony.detail.file.bride_family_card
														}
													>
														Lihat disini
													</a>
												</td>
											</tr>
										</table>

										<h3>Akta Lahir</h3>
										<table>
											<tr>
												<td>Pria</td>
												<td>:</td>
												<td>
													<a
														href={
															this.props.matrimony.detail.file &&
															this.props.matrimony.detail.file.groom_birth_cert
														}
													>
														Lihat disini
													</a>
												</td>
											</tr>

											<tr>
												<td>Wanita</td>
												<td>:</td>
												<td>
													<a
														href={
															this.props.matrimony.detail.file &&
															this.props.matrimony.detail.file.bride_birth_cert
														}
													>
														Lihat disini
													</a>
												</td>
											</tr>
										</table>

										<h3>Akta Baptis</h3>
										<table>
											<tr>
												<td>Pria</td>
												<td>:</td>
												<td>
													<a
														href={
															this.props.matrimony.detail.file &&
															this.props.matrimony.detail.file
																.groom_baptism_cert
														}
													>
														Lihat disini
													</a>
												</td>
											</tr>

											<tr>
												<td>Wanita</td>
												<td>:</td>
												<td>
													<a
														href={
															this.props.matrimony.detail.file &&
															this.props.matrimony.detail.file
																.bride_baptism_cert
														}
													>
														Lihat disini
													</a>
												</td>
											</tr>
										</table>

										<h3>Surat Persetujuan Orang Tua/Wali</h3>
										<table>
											<tr>
												<td>Pria</td>
												<td>:</td>
												<td>
													<a
														href={
															this.props.matrimony.detail.file &&
															this.props.matrimony.detail.file
																.groom_parental_consent_letter
														}
													>
														Lihat disini
													</a>
												</td>
											</tr>

											<tr>
												<td>Wanita</td>
												<td>:</td>
												<td>
													<a
														href={
															this.props.matrimony.detail.file &&
															this.props.matrimony.detail.file
																.bride_parental_consent_letter
														}
													>
														Lihat disini
													</a>
												</td>
											</tr>
										</table>

										<h3>Formulir N1</h3>
										<table>
											<tr>
												<td>Pria</td>
												<td>:</td>
												<td>
													<a
														href={
															this.props.matrimony.detail.file &&
															this.props.matrimony.detail.file.groom_n1_form
														}
													>
														Lihat disini
													</a>
												</td>
											</tr>

											<tr>
												<td>Wanita</td>
												<td>:</td>
												<td>
													<a
														href={
															this.props.matrimony.detail.file &&
															this.props.matrimony.detail.file.bride_n1_form
														}
													>
														Lihat disini
													</a>
												</td>
											</tr>
										</table>

										<h3>Formulir N2</h3>
										<table>
											<tr>
												<td>Pria</td>
												<td>:</td>
												<td>
													<a
														href={
															this.props.matrimony.detail.file &&
															this.props.matrimony.detail.file.groom_n2_form
														}
													>
														Lihat disini
													</a>
												</td>
											</tr>

											<tr>
												<td>Wanita</td>
												<td>:</td>
												<td>
													<a
														href={
															this.props.matrimony.detail.file &&
															this.props.matrimony.detail.file.bride_n2_form
														}
													>
														Lihat disini
													</a>
												</td>
											</tr>
										</table>

										<h3>Formulir N4</h3>
										<table>
											<tr>
												<td>Pria</td>
												<td>:</td>
												<td>
													<a
														href={
															this.props.matrimony.detail.file &&
															this.props.matrimony.detail.file.groom_n4_form
														}
													>
														Lihat disini
													</a>
												</td>
											</tr>

											<tr>
												<td>Wanita</td>
												<td>:</td>
												<td>
													<a
														href={
															this.props.matrimony.detail.file &&
															this.props.matrimony.detail.file.bride_n4_form
														}
													>
														Lihat disini
													</a>
												</td>
											</tr>
										</table>

										<h3>Dokumen Lainnya</h3>
										<table>
											<tr>
												<td>Surat Pernyataan</td>
												<td>:</td>
												<td>
													<a
														href={
															this.props.matrimony.detail.file &&
															this.props.matrimony.detail.file.statement_letter
														}
													>
														Lihat disini
													</a>
												</td>
											</tr>

											<tr>
												<td>Surat Keterangan</td>
												<td>:</td>
												<td>
													<a
														href={
															this.props.matrimony.detail.file &&
															this.props.matrimony.detail.file
																.letter_of_statement
														}
													>
														Lihat disini
													</a>
												</td>
											</tr>
										</table>

										<div className='d-flex justify-content-end'>
											<Button
												color='primary'
												onClick={() => {
													this.props.history.push("/admin/sacrament/baptism")
												}}
											>
												Kembali
											</Button>
										</div>
									</Container>
								</CardBody>
							</Card>
						</Col>
					</Row>
				</Container>
			</>
		)
	}
}

const mapStateToProps = (state) => {
	return { matrimony: state.matrimony }
}

const mapDispatchToProps = {
	getMatrimonialDetail: sacrament.getMatrimonialDetail,
}

export default connect(mapStateToProps, mapDispatchToProps)(MatrimonialDetail)
