import getMinistries from "./getMinistries"
import getMinistryContent from "./getMinistryContent"
import updateMinistryContent from "./updateMinistryContent"
import deleteMinistry from "./deleteMinistry"
import addMinistry from "./addMinistry"
import updateMinistry from "./updateMinistry"

export {
	getMinistries,
	updateMinistryContent,
	getMinistryContent,
	deleteMinistry,
	addMinistry,
	updateMinistry,
}

const ministry = {
	getMinistries,
	updateMinistryContent,
	getMinistryContent,
	deleteMinistry,
	addMinistry,
	updateMinistry,
}

export default ministry
