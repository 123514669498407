function pendingState(type) {
	return `${type}_PENDING`
}

function rejectedState(type) {
	return `${type}_REJECTED`
}

function fulfilledState(type) {
	return `${type}_FULFILLED`
}

export { pendingState, rejectedState, fulfilledState }

const promiseState = {
	pendingState,
	rejectedState,
	fulfilledState,
}

export default promiseState
