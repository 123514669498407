import { axiosInstance } from "../../../helper"
import { video } from "../types"

export function deleteVideo(id, config) {
	return {
		type: video.DELETE_VIDEO,
		payload: new axiosInstance.delete(`/admin/streaming-video/${id}`, config),
	}
}

export default deleteVideo
