import { axiosInstance } from "../../../helper"
import { event } from "../types"

export function deleteEvent(id, config) {
	return {
		type: event.DELETE,
		payload: new axiosInstance.delete(`/admin/event/${id}`, config),
	}
}

export default deleteEvent
