import { axiosInstance } from "../../../helper"
import { secretariat } from "../types"

export function getSecretariatDetail(id, params, config) {
	return {
		type: secretariat.GET_SECRETARIAT_DETAIL,
		payload: new axiosInstance.get(`/admin/secretariat/${id}`, {
			...config,
			params,
		}),
	}
}

export default getSecretariatDetail
