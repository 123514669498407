import { Component } from "react"
import { BrowserRouter, Switch, Redirect } from "react-router-dom"

import AdminLayout from "./layouts/Admin.js"
import AuthLayout from "./layouts/Auth.js"

import { ProtectedRoute, AuthRoute } from "./components/Auth"

class App extends Component {
	render() {
		return (
			<BrowserRouter>
				<Switch>
					<ProtectedRoute
						path='/admin'
						render={(props) => <AdminLayout {...props} />}
					/>
					<AuthRoute
						path='/auth'
						render={(props) => <AuthLayout {...props} />}
					/>
					<Redirect from='/' exact to='/admin/index' />
				</Switch>
			</BrowserRouter>
		)
	}
}

export default App
