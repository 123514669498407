import { promiseState } from "../../helper"
import { dashboard } from "../actions/types"

const { pendingState, fulfilledState, rejectedState } = promiseState

const initialState = {
	isLoading: false,
	message: null,
	errors: [],
	card: {
		lastWeek: {
			worship: {},
			event: {},
			startDate: "",
			endDate: "",
		},

		thisWeek: {
			worship: {},
			event: {},
			startDate: "",
			endDate: "",
		},
	},
	userChart: {
		labels: [],
		datasets: [
			{
				label: "Total",
				data: [],
				borderColor: "rgba(45, 156, 219, 1)",
			},
		],
	},
	scheduleChart: {
		labels: [],
		datasets: [
			{
				label: "Dipesan",
				data: [],
				borderColor: "rgba(45, 156, 219, 1)",
			},
		],
	},
}

const dashboardReducer = (state = initialState, action) => {
	switch (action.type) {
		case pendingState(dashboard.CARD): {
			return Object.assign({}, state, {
				isLoading: true,
			})
		}

		case rejectedState(dashboard.CARD): {
			return Object.assign({}, state, {
				isLoading: false,
			})
		}

		case fulfilledState(dashboard.CARD): {
			return Object.assign({}, state, {
				isLoading: false,
				card: action.payload.data.data,
			})
		}

		case pendingState(dashboard.USER): {
			return Object.assign({}, state, {
				isLoading: true,
			})
		}

		case rejectedState(dashboard.USER): {
			return Object.assign({}, state, {
				isLoading: false,
			})
		}

		case fulfilledState(dashboard.USER): {
			return Object.assign({}, state, {
				isLoading: false,
				userChart: action.payload.data.data,
			})
		}

		case pendingState(dashboard.SCHEDULE): {
			return Object.assign({}, state, {
				isLoading: true,
			})
		}

		case rejectedState(dashboard.SCHEDULE): {
			return Object.assign({}, state, {
				isLoading: false,
			})
		}

		case fulfilledState(dashboard.SCHEDULE): {
			return Object.assign({}, state, {
				isLoading: false,
				scheduleChart: action.payload.data.data,
			})
		}

		default:
			return state
	}
}

export default dashboardReducer
