import {
	Card,
	CardHeader,
	CardFooter,
	Table,
	Container,
	Row,
	Breadcrumb,
	BreadcrumbItem,
	Label,
	Input,
} from "reactstrap"
// core components
import { Component } from "react"
import { Link } from "react-router-dom"

import moment from "moment"
import { connect } from "react-redux"
import { worship, church } from "../../../redux/actions"
import { getColorPercent } from "../../../helper"

class Worship extends Component {
	constructor(props) {
		super(props)
		let date = moment().format("YYYY-MM-DD")
		if (this.props.location.state && this.props.location.state.date) {
			date = this.props.location.state.date
		}
		this.state = {
			date: date,
			churchId: this.props.match.params.churchId,
		}
	}

	getWorship() {
		this.props.getWorship({
			date: this.state.date,
			church_id: this.state.churchId,
		})
	}

	getChurch() {
		this.props.getChurch(this.state.churchId)
	}
	componentDidMount() {
		this.getChurch()
		this.getWorship()
		if (this.props.location.state && this.props.location.state.date) {
			this.setState({
				date: this.props.location.state.date,
			})
		}
	}

	render() {
		return (
			<>
				{/* Page content */}
				<Container fluid className='mb-5'>
					<div>
						<Breadcrumb>
							<BreadcrumbItem>
								<Link to='/admin/schedule/worship'>Schedule</Link>
							</BreadcrumbItem>
							<BreadcrumbItem>
								<Link to='/admin/schedule/worship'>Ibadah Minggu</Link>
							</BreadcrumbItem>
							<BreadcrumbItem active>{this.props.church.name}</BreadcrumbItem>
						</Breadcrumb>
					</div>
					{/* Table */}
					<Row>
						<div className='col'>
							<Card>
								<CardHeader>
									<h3 className='mb-0 mr-auto'>{this.props.church.name}</h3>
									<p>{this.props.church.address}</p>
								</CardHeader>
								<CardHeader className='d-flex'>
									<div>
										<Label for='dateFilter'>Filter Tanggal</Label>
										<Input
											id='dateFilter'
											name='dateFilter'
											type='date'
											value={this.state.date}
											onChange={(e) => {
												this.setState({ date: e.target.value }, () => {
													this.getWorship()
												})
											}}
										/>
									</div>
									<div className='ml-auto'>
										<Link
											to={{
												pathname: `/admin/schedule/worship/${this.state.churchId}/create`,
												state: {
													date: this.state.date,
												},
											}}
											className='btn btn-md btn-primary'
										>
											Tambah Ibadah Minggu
										</Link>
									</div>
								</CardHeader>
								<Table
									className='align-items-center table-flush table-striped'
									responsive
								>
									<thead className='thead-light'>
										<tr>
											<th scope='col'>No.</th>
											<th scope='col'>Waktu</th>
											<th scope='col'>Kapasitas</th>
											<th scope='col'>Action</th>
											{/* <th scope='col'></th>
											<th scope='col'></th>
											<th scope='col'></th>
											<th scope='col'></th> */}
										</tr>
									</thead>
									<tbody>
										{this.props.worship.data.EventDateTimes.map((time, i) => (
											<tr>
												<td>{i + 1}</td>
												<td>{time.time}</td>
												<td>
													<span
														style={{
															color: getColorPercent(
																time.total_reservation || 0,
																this.props.worship.data.capacity
															),
														}}
													>{`${time.total_reservation || 0} `}</span>
													/{` ${time.capacity}`}
												</td>
												<td>
													<Link
														to={`/admin/schedule/worship/${this.state.churchId}/${time.id}`}
													>
														Lihat Detail
													</Link>
												</td>
												{/* <td></td>
												<td></td>
												<td></td>
												<td></td> */}
											</tr>
										))}

										{this.props.worship.data.EventDateTimes.length <= 0 ? (
											<tr>
												<td colSpan={8}>Tidak ada data</td>
											</tr>
										) : null}
									</tbody>
								</Table>
								<CardFooter className='py-4 d-flex'>
									<p className='ml-auto'>
										{`Menampilkan
										${this.props.worship.data.EventDateTimes?.length || 0}
										dari ${this.props.worship.data.EventDateTimes?.length || 0}
										data`}
									</p>
								</CardFooter>
							</Card>
						</div>
					</Row>
				</Container>
			</>
		)
	}
}

const mapStateToProps = (state) => {
	return {
		worship: state.worship,
		church: state.church.detail,
	}
}

const mapDispatchToProps = {
	getWorship: worship.getWorship,
	getChurch: church.getChurch,
}

export default connect(mapStateToProps, mapDispatchToProps)(Worship)
