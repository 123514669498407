import {
  Card,
  Input,
  Container,
  Breadcrumb,
  BreadcrumbItem,
  Nav,
  NavItem,
  NavLink,
  Col,
  CardBody,
} from "reactstrap"
// core components
import { Component } from "react"
import { Link, NavLink as NavLinkRRD } from "react-router-dom"
import InfiniteScroll from "react-infinite-scroll-component"
import { connect } from "react-redux"
import { church } from "../../../redux/actions"

class Schedule extends Component {
  constructor() {
    super()

    this.state = {
      data: [
        {
          title: 0,
        },
      ],
      params: {
        limit: 10,
        search: "",
        page: 1,
      },
    }
  }

  fetchData(e) {
    this.props.nextChurches({
      page: this.props.church.page.currentPage + 1,
      limit: this.state.params.limit,
    })
  }

  getChurches() {
    this.props.getChurches(this.state.params)
  }
  componentDidMount() {
    this.getChurches()
  }

  render() {
    return (
      <>
        {/* Page content */}
        <Container fluid className="mb-5">
          <div>
            <Breadcrumb>
              <BreadcrumbItem active>Schedule</BreadcrumbItem>
            </Breadcrumb>
          </div>

          <div className="schedule-nav mb-3">
            <Nav>
              <NavItem>
                <NavLink tag={NavLinkRRD} to="/admin/schedule/worship">
                  Ibadah Minggu
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink tag={NavLinkRRD} to="/admin/schedule/event">
                  Event
                </NavLink>
              </NavItem>
            </Nav>
          </div>

          <div className="mb-3 row">
            <Col xl={5}>
              <Input
                type="text"
                className="form-control form-search"
                placeholder="Cari berdasarkan nama tempat"
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    this.getChurches()
                  }
                }}
                onChange={(e) => {
                  this.setState({
                    params: Object.assign({}, this.state.params, {
                      search: e.target.value,
                    }),
                  })
                }}
              />
            </Col>
          </div>
          <InfiniteScroll
            className="row mb-4 card-event card-worship"
            dataLength={this.props.church.data.length} //This is important field to render the next data
            next={this.fetchData.bind(this)}
            hasMore={this.props.church.hasMore}
            loader={
              <Col xl={12}>
                <p style={{ textAlign: "center" }}>
                  <b>Loading...</b>
                </p>
              </Col>
            }
            endMessage={
              <Col xl={12}>
                <p style={{ textAlign: "center" }}>
                  <b>Yay! You have seen it all</b>
                </p>
              </Col>
            }
          >
            {this.props.church.data.map((v) => (
              <Col xl={4} className="mb-3">
                <Card>
                  <div className="card-img-top">
                    <img src={v.image} alt={v.image} />
                  </div>
                  <CardBody>
                    <h5>{v.name}</h5>

                    <div className="bottom-card">
                      <Link
                        to={`/admin/schedule/worship/${v.id}`}
                        className="btn btn-primary"
                      >
                        Lihat Schedule
                      </Link>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            ))}
          </InfiniteScroll>
        </Container>
      </>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    church: state.church,
  }
}

const mapDispatchToProps = {
  getChurches: church.getChurches,
  nextChurches: church.nextChurches,
}

export default connect(mapStateToProps, mapDispatchToProps)(Schedule)
