export const church = {
	GET_CHURCHES: "church/get",
	ADD_CHURCH: "church/add",
	UPDATE_CHURCH: "church/update",
	DELETE_CHURCH: "church/delete",
	NEXT_CHURCHES: "church/next",
	GET_DETAIL_CHURCH: "church/detail",
}

export default church
