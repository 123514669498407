import { axiosInstance } from "../../../helper"
import { sacrament } from "../types"

export function getMatrimonialDetail(id, params, config) {
	return {
		type: sacrament.GET_MATRIMONY_DETAIL,
		payload: new axiosInstance.get(`/admin/sacrament/matrimony/${id}`, {
			...config,
			params,
		}),
	}
}

export default getMatrimonialDetail
