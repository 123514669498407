import { promiseState } from "../../helper"
import { instagram } from "../actions/types"
import { toast } from "react-toastify"

const { pendingState, fulfilledState, rejectedState } = promiseState

const initialState = {
	isLoading: false,
	message: null,
	errors: [],
	data: "",
}

const instagramReducer = (state = initialState, action) => {
	switch (action.type) {
		case pendingState(instagram.GET_IG_PROFILE): {
			return Object.assign({}, state, {
				isLoading: true,
			})
		}

		case rejectedState(instagram.GET_IG_PROFILE): {
			return Object.assign({}, state, {
				isLoading: false,
			})
		}

		case fulfilledState(instagram.GET_IG_PROFILE): {
			return Object.assign({}, state, {
				isLoading: false,
				data: action.payload.data.data,
			})
		}

		case pendingState(instagram.CHANGE_IG_PROFILE): {
			return Object.assign({}, state, {
				isLoading: true,
			})
		}

		case rejectedState(instagram.CHANGE_IG_PROFILE): {
			toast.error(
				action?.payload?.response
					? action?.payload?.response.data.message
					: action?.payload?.message
			)
			return Object.assign({}, state, {
				isLoading: false,
				message: action?.payload?.response
					? action?.payload?.response.data.message
					: action?.payload?.message,
			})
		}

		case fulfilledState(instagram.CHANGE_IG_PROFILE): {
			return Object.assign({}, state, {
				isLoading: false,
				data: action.payload.data.data,
			})
		}

		default:
			return state
	}
}

export default instagramReducer
