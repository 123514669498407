import { promiseState } from "../../helper"
import { worshipTime } from "../actions/types"

const { pendingState, fulfilledState, rejectedState } = promiseState

const initialState = {
	isLoading: false,
	errors: [],
	message: "",
	data: {
		id: "",
		time: "",
		date: "",
	},
}

function worshipTimeReducer(state = initialState, action) {
	switch (action.type) {
		case pendingState(worshipTime.GET_WORSHIP_TIME): {
			return Object.assign({}, state, {
				isLoading: true,
			})
		}

		case rejectedState(worshipTime.GET_WORSHIP_TIME): {
			return Object.assign({}, state, {
				isLoading: false,
			})
		}

		case fulfilledState(worshipTime.GET_WORSHIP_TIME): {
			return Object.assign({}, state, {
				isLoading: false,
				data: action.payload.data.data,
			})
		}

		case pendingState(worshipTime.ADD_WORSHIP_TIME): {
			return Object.assign({}, state, {
				isLoading: true,
			})
		}

		case rejectedState(worshipTime.ADD_WORSHIP_TIME): {
			return Object.assign({}, state, {
				isLoading: false,
			})
		}

		case fulfilledState(worshipTime.ADD_WORSHIP_TIME): {
			return Object.assign({}, state, {
				isLoading: false,
				data: action.payload.data.data,
			})
		}

		default:
			return state
	}
}

export default worshipTimeReducer
