import { user } from "../types"
import axiosInstance from "../../../helper/axiosInstance"

export function getUsers(params, config) {
	return {
		type: user.GET_USER,
		payload: new axiosInstance.get("/user", {
			params,
			...config,
		}),
	}
}

export default getUsers
