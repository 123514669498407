/*!

=========================================================
* Argon Dashboard React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react"
import ReactDOM from "react-dom"

import "./assets/plugins/nucleo/css/nucleo.css"
import "@fortawesome/fontawesome-free/css/all.min.css"
import "./assets/scss/argon-dashboard-react.scss"
import "./assets/glowfc/css/custom.css"
import "react-toastify/dist/ReactToastify.css"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

import App from "./App"
import { Provider } from "react-redux"
import storage from "./redux/store"
import { PersistGate } from "redux-persist/integration/react"
import { ToastContainer } from "react-toastify"

import { initializeApp } from "firebase/app"
import { getAnalytics } from "firebase/analytics"

const firebaseConfig = {
	apiKey: "AIzaSyCLsfYnDXF9MgqkXkROw0FrD8aT9Cam2CI",
	authDomain: "glowfc-e35c7.firebaseapp.com",
	projectId: "glowfc-e35c7",
	storageBucket: "glowfc-e35c7.appspot.com",
	messagingSenderId: "366516525168",
	appId: "1:366516525168:web:cf98804e67c4690fefbcb5",
	measurementId: "G-1DEWNQLJY9",
}

// Initialize Firebase
const app = initializeApp(firebaseConfig)
getAnalytics(app)

const { store, persistor } = storage

ReactDOM.render(
	<React.Fragment>
		<Provider store={store}>
			<PersistGate persistor={persistor}>
				<App />
				<ToastContainer />
			</PersistGate>
		</Provider>
	</React.Fragment>,
	document.getElementById("root")
)
