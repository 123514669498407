import { promiseState } from "../../helper"
import { user } from "../actions/types"

const { pendingState, fulfilledState, rejectedState } = promiseState

const initialState = {
	isLoading: false,
	message: null,
	errors: [],
	data: [],
	page: {
		totalPage: 0,
		currentPage: 1,
		totalData: 0,
	},
	profile: {},
	detail: {
		UserDetail: {},
	},
}

const userReducer = (state = initialState, action) => {
	switch (action.type) {
		case pendingState(user.GET_USER): {
			return Object.assign({}, state, {
				isLoading: true,
			})
		}

		case rejectedState(user.GET_USER): {
			return Object.assign({}, state, {
				isLoading: false,
			})
		}

		case fulfilledState(user.GET_USER): {
			return Object.assign({}, state, {
				isLoading: false,
				data: action.payload.data.data,
				page: {
					totalPage: action.payload.data.page.totalPage,
					currentPage: action.payload.data.page.currentPage,
					totalData: action.payload.data.page.totalData,
				},
			})
		}

		case pendingState(user.GET_PROFILE): {
			return Object.assign({}, state, {
				isLoading: true,
			})
		}

		case rejectedState(user.GET_PROFILE): {
			return Object.assign({}, state, {
				isLoading: false,
			})
		}

		case fulfilledState(user.GET_PROFILE): {
			return Object.assign({}, state, {
				isLoading: false,
				profile: action.payload.data.data,
			})
		}

		case pendingState(user.GET_DETAIL_USER): {
			return Object.assign({}, state, {
				isLoading: true,
			})
		}

		case rejectedState(user.GET_DETAIL_USER): {
			return Object.assign({}, state, {
				isLoading: false,
			})
		}

		case fulfilledState(user.GET_DETAIL_USER): {
			return Object.assign({}, state, {
				isLoading: false,
				detail: action.payload.data.data,
			})
		}

		default:
			return state
	}
}

export default userReducer
