import getBaptisms from "./getBaptisms"
import getMatrimonial from "./getMatrimonial"
import getChildSubmissions from "./getChildSubmissions"
import getBaptismDetail from "./getBaptismDetail"
import getChildSubmissionDetail from "./getChildSubmissionDetail"
import getMatrimonialDetail from "./getMatrimonialDetail"

export {
	getBaptisms,
	getMatrimonial,
	getChildSubmissions,
	getBaptismDetail,
	getChildSubmissionDetail,
	getMatrimonialDetail,
}

const sacrament = {
	getBaptisms,
	getMatrimonial,
	getChildSubmissions,
	getBaptismDetail,
	getChildSubmissionDetail,
	getMatrimonialDetail,
}

export default sacrament
