import { axiosInstance } from "../../../helper"
import { ministry } from "../types"

export function getMinistries(params, config) {
	return {
		type: ministry.GET_MINISTRIES,
		payload: new axiosInstance.get("/ministry", {
			...config,
			params,
		}),
	}
}

export default getMinistries
