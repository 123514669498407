export const ministry = {
	GET_MINISTRIES: "ministry/get",
	ADD_MINISTRY: "ministry/add",
	UPDATE_MINISTRY: "ministry/update",
	DELETE_MINISTRY: "ministry/delete",
	GET_MINISTRY_CONTENT: "ministry/content/detail",
	UPDATE_MINISTRY_CONTENT: "ministry/content/update",
}

export default ministry
