const video = {
	GET_VIDEO: "video/get",
	ADD_VIDEO: "video/add",
	UPDATE_VIDEO: "video/update",
	GET_VIDEO_DETAIL: "video/detail",
	DELETE_VIDEO: "video/delete",
	CHANGE_TYPE_VIDEO: "video/change-type",
	GET_STREAMING_VIDEO: "video/get/streaming",
	GET_SPECIAL_GUEST: "video/get/special-guest",
}

export default video
