import { axiosInstance } from "../../../helper"
import { video } from "../types"

export function addVideo(body, config) {
	return {
		type: video.ADD_VIDEO,
		payload: new axiosInstance.post(`/admin/streaming-video/`, body, config),
	}
}

export default addVideo
