import { magazine } from "../types"
import { axiosInstance } from "../../../helper"

export function changeMagazine(body, config) {
	return {
		type: magazine.CHANGE_MAGAZINE,
		payload: new axiosInstance.post("/weekly-magazine", body, config),
	}
}

export default changeMagazine
