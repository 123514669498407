import {
	Card,
	CardBody,
	Container,
	Row,
	Col,
	Button,
	Breadcrumb,
	BreadcrumbItem,
	CardHeader,
} from "reactstrap"
// core components
import { Component } from "react"
import { Link } from "react-router-dom"
import { connect } from "react-redux"
import { sacrament } from "../../../redux/actions"

import moment from "moment"

class BaptismDetail extends Component {
	getBaptismDetail() {
		this.props.getBaptismDetail(this.props.match.params.baptismId)
	}
	componentDidMount() {
		this.getBaptismDetail()
	}

	render() {
		return (
			<>
				{/* Page content */}
				<Container fluid className='mb-5'>
					<div>
						<Breadcrumb>
							<BreadcrumbItem>
								<Link to='/admin/sacrament'>Sakramen</Link>
							</BreadcrumbItem>

							<BreadcrumbItem>
								<Link to='/admin/sacrament/baptism'>Baptisan</Link>
							</BreadcrumbItem>

							<BreadcrumbItem active>Informasi Detail</BreadcrumbItem>
						</Breadcrumb>
					</div>
					{/* Table */}
					<Row>
						<Col xl={7}>
							<Card>
								<CardHeader>
									<h1>Informasi Detail</h1>
								</CardHeader>
								<CardBody>
									<Container>
										<table>
											<tr>
												<td
													style={{
														minWidth: "200px",
													}}
												>
													Nama
												</td>
												<td>{this.props.baptism.detail.name}</td>
											</tr>

											<tr>
												<td>Tempat Lahir</td>
												<td>{this.props.baptism.detail.birthplace}</td>
											</tr>

											<tr>
												<td>Tanggal Lahir</td>
												<td>
													{moment(
														this.props.baptism.detail.birthdate,
														"YYYY-MM-DD"
													)
														.locale("id")
														.format("DD MMM 2021")}
												</td>
											</tr>

											<tr>
												<td>Nama Ayah</td>
												<td>{this.props.baptism.detail.father_name}</td>
											</tr>

											<tr>
												<td>Nama Ibu</td>
												<td>{this.props.baptism.detail.mother_name}</td>
											</tr>

											<tr>
												<td>Alamat Lengkap</td>
												<td>{this.props.baptism.detail.address}</td>
											</tr>

											<tr>
												<td>No. Whatsapp</td>
												<td>{this.props.baptism.detail.phone}</td>
											</tr>

											<tr>
												<td>Dokumen</td>
												<td>
													<Link
														to={{
															pathname: this.props.baptism.detail.image,
														}}
														target='_blank'
													>
														Klik Disini
													</Link>
												</td>
											</tr>
										</table>

										<div className='d-flex justify-content-end'>
											<Button
												color='primary'
												onClick={() => {
													this.props.history.push("/admin/sacrament/baptism")
												}}
											>
												Kembali
											</Button>
										</div>
									</Container>
								</CardBody>
							</Card>
						</Col>
					</Row>
				</Container>
			</>
		)
	}
}

const mapStateToProps = (state) => {
	return { baptism: state.baptism }
}

const mapDispatchToProps = {
	getBaptismDetail: sacrament.getBaptismDetail,
}

export default connect(mapStateToProps, mapDispatchToProps)(BaptismDetail)
