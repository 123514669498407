import { axiosInstance } from "../../../helper"
import { event } from "../types"

export function getEvents(params, config) {
	return {
		type: event.GET_EVENTS,
		payload: new axiosInstance.get("/admin/event", {
			...config,
			params,
		}),
	}
}

export default getEvents
