import { axiosInstance } from "../../../helper"
import { resource } from "../types"

export function deleteResource(id, config) {
	return {
		type: resource.DELETE_RESOURCE,
		payload: new axiosInstance.delete(`/admin/resource/${id}`, config),
	}
}

export default deleteResource
