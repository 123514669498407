import { axiosInstance } from "../../../helper"
import { ministry } from "../types"

export function getMinistryContent(id, params, config) {
	return {
		type: ministry.GET_MINISTRY_CONTENT,
		payload: new axiosInstance.get(`/admin/ministry/${id}/content`, {
			...config,
			params,
		}),
	}
}

export default getMinistryContent
