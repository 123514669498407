import {
	Card,
	CardBody,
	Container,
	Row,
	Col,
	Form,
	FormGroup,
	Input,
	Label,
	Button,
	Breadcrumb,
	BreadcrumbItem,
} from "reactstrap"
// core components
import { Component } from "react"
import { Link } from "react-router-dom"
import Joi from "joi"
import ReactSelect from "react-select"
import { connect } from "react-redux"
import { video, leader } from "../../../redux/actions"
import { toast } from "react-toastify"
import Swal from "sweetalert2"

const typeOptions = [
	{
		value: null,
		label: "None",
	},
	{
		value: 1,
		label: "Streaming Resource",
	},
	{
		value: 2,
		label: "Special Guest",
	},
]
class CreateVideo extends Component {
	constructor(props) {
		super(props)

		this.state = {
			title: {
				value: "",
				rule: Joi.string().required(),
				valid: true,
				errMsg: "",
			},

			description: {
				value: "",
				rule: Joi.string().required(),
				valid: true,
				errMsg: "",
			},

			youtube_url: {
				value: "",
				rule: Joi.string().required(),
				valid: true,
				errMsg: "",
			},

			leader_id: null,
			type: null,

			creatorValue: null,
			typeValue: null,

			videoId: this.props.match.params.videoId,

			disable: this.props.match.params.videoId !== "create",
		}
	}

	inputOnChange(name, value) {
		const fieldProperties = { ...this.state }
		const isValid = fieldProperties[name].rule.validate(value)

		fieldProperties[name].valid = !isValid.error
		fieldProperties[name].value = value

		if (isValid.error) {
			fieldProperties[name].errMsg = isValid.error.message
		}

		this.setState(fieldProperties)
	}

	goBack() {
		this.props.history.push("/admin/video-resource")
	}

	proxySubmit(e) {
		e.preventDefault()

		if (this.state.videoId === "create") {
			return this.addVideo()
		} else {
			return this.updateVideo()
		}
	}

	handleDelete() {
		Swal.fire({
			title: "Are you sure?",
			text: "You won't be able to revert this!",
			icon: "warning",
			showCancelButton: true,
			confirmButtonColor: "#3085d6",
			cancelButtonColor: "#d33",
			confirmButtonText: "Yes, delete it!",
		}).then((result) => {
			if (result.isConfirmed) {
				this.props.deleteVideo(this.props.match.params.videoId).then(() => {
					Swal.fire("Deleted!", "Video has been deleted.", "success")
					this.props.history.push(`/admin/video-resource`)
				})
			}
		})
	}

	updateVideo() {
		this.props
			.updateVideo(this.props.match.params.videoId, {
				leader_id: this.state.leader_id,
				title: this.state.title.value,
				description: this.state.description.value,
				youtube_url: this.state.youtube_url.value,
				type: this.state.type,
			})
			.then(() => {
				toast.success("Berhasil Edit Video")
				this.getVideoDetail()

				this.setState({
					disable: true,
				})
			})
	}

	addVideo() {
		this.props
			.addVideo({
				leader_id: this.state.leader_id,
				title: this.state.title.value,
				description: this.state.description.value,
				youtube_url: this.state.youtube_url.value,
				type: this.state.type,
			})
			.then(() => {
				toast.success("Tambah Video Berhasil")

				this.props.history.push("/admin/video-resource")
			})
	}

	getLeader() {
		this.props.getLeader()
	}

	getVideoDetail() {
		this.props.getVideoDetail(this.props.match.params.videoId).then(() => {
			const fieldProperties = { ...this.state }
			fieldProperties.title.value = this.props.video.detail.title
			fieldProperties.description.value = this.props.video.detail.description
			fieldProperties.youtube_url.value = this.props.video.detail.youtube_url

			if (this.props.video.detail.Leader) {
				fieldProperties.creatorValue = {
					label: this.props.video.detail.Leader.name,
					value: this.props.video.detail.leader_id,
				}
			} else {
				fieldProperties.creatorValue = {
					label: "Other",
					value: null,
				}
			}

			if (this.props.video.detail.OtherTypeVideo) {
				const find = typeOptions.find((value) => {
					return value.value === this.props.video.detail.OtherTypeVideo.type
				})
				fieldProperties.typeValue = find
			} else {
				fieldProperties.typeValue = {
					label: "None",
					value: null,
				}
			}

			this.setState(fieldProperties)
		})
	}

	componentDidMount() {
		this.getLeader()

		if (this.props.match.params.videoId !== "create") {
			this.getVideoDetail()
		}
	}

	render() {
		const leader = this.props.leader.data.map((leader) => {
			return {
				label: leader.name,
				value: leader.id,
			}
		})
		const CreatorData = [
			...leader,
			{
				label: "Other",
				value: null,
			},
		]
		return (
			<>
				{/* Page content */}
				<Container fluid className='mb-5'>
					<div>
						<Breadcrumb>
							<BreadcrumbItem>
								<Link to='/admin/video-resource'>Video Resources</Link>
							</BreadcrumbItem>
							<BreadcrumbItem active>Tambah Video</BreadcrumbItem>
						</Breadcrumb>
					</div>
					{/* Table */}
					<Row>
						<Col xl={12}>
							<Card>
								<CardBody>
									<h1 className='mb-4'>Detail Video</h1>
									<Row>
										<Col xl={8}>
											<Form
												onSubmit={this.proxySubmit.bind(this)}
												id='create-video-form'
											>
												<FormGroup>
													<Label for='name'>Judul Video</Label>
													<Input
														type='text'
														name='name'
														id='name'
														placeholder='Masukan Judul Video'
														disabled={this.state.disable}
														onChange={(e) =>
															this.inputOnChange("title", e.target.value)
														}
														value={this.state.title.value}
													/>
												</FormGroup>

												<FormGroup>
													<Label for='description'>Creator</Label>
													<ReactSelect
														placeholder='Pilih salah satu'
														options={CreatorData}
														isDisabled={this.state.disable}
														onChange={(e) =>
															this.setState({
																creatorValue: e,
																leader_id: e.value,
															})
														}
														value={this.state.creatorValue}
													/>
												</FormGroup>

												<FormGroup>
													<Label for='description'>Deskripsi</Label>
													<Input
														id='description'
														name='description'
														type='textarea'
														placeholder='Masukan Deskripsi'
														disabled={this.state.disable}
														onChange={(e) =>
															this.inputOnChange("description", e.target.value)
														}
														value={this.state.description.value}
													/>
												</FormGroup>
												<FormGroup>
													<Label for='name'>URL Video</Label>
													<Input
														type='text'
														name='name'
														id='name'
														placeholder='Masukan URL'
														disabled={this.state.disable}
														onChange={(e) =>
															this.inputOnChange("youtube_url", e.target.value)
														}
														value={this.state.youtube_url.value}
													/>
												</FormGroup>

												<FormGroup>
													<Label for='description'>Set Video Sebagai</Label>
													<ReactSelect
														options={typeOptions}
														placeholder='Pilih salah satu'
														isDisabled={this.state.disable}
														onChange={(e) =>
															this.setState({
																typeValue: e,
																type: e.value,
															})
														}
														value={this.state.typeValue}
													/>
												</FormGroup>
											</Form>
										</Col>
									</Row>
									<Container className='d-flex'>
										<div className='d-flex flex-row align-items-center mb-3 ml-auto'>
											{this.state.disable ? (
												<>
													<Button
														color='primary'
														outline
														onClick={this.handleDelete.bind(this)}
													>
														Hapus
													</Button>
													<div>
														<Button
															color='primary'
															type='button'
															onClick={() => {
																this.setState({
																	disable: !this.state.disable,
																})
															}}
														>
															Edit Video
														</Button>
													</div>
												</>
											) : (
												<>
													<Button
														color='primary'
														outline
														onClick={this.goBack.bind(this)}
													>
														Batal
													</Button>
													<Button
														color='primary'
														type='submit'
														form='create-video-form'
													>
														Simpan
													</Button>
												</>
											)}
										</div>
									</Container>
								</CardBody>
							</Card>
						</Col>
					</Row>
				</Container>
			</>
		)
	}
}

const mapStateToProps = (state) => {
	return {
		video: state.video,
		leader: state.leader,
	}
}

const mapDispatchToProps = {
	addVideo: video.addVideo,
	updateVideo: video.updateVideo,
	getLeader: leader.getLeader,
	getVideoDetail: video.getVideoDetail,
	deleteVideo: video.deleteVideo,
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateVideo)
