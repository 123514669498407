import { axiosInstance } from "../../../helper"
import { account } from "../types"

export function updateAccount(id, data, config) {
	return {
		type: account.UPDATE,
		payload: new axiosInstance.put(
			`/admin/user/${id}`,
			data,
			config
		),
	}
}

export default updateAccount
