import { axiosInstance } from "../../../helper"
import { resource } from "../types"

export function getResource(id, params, config) {
	return {
		type: resource.GET_RESOURCE_DETAIL,
		payload: new axiosInstance.get(`/admin/resource/${id}`, {
			...config,
			params,
		}),
	}
}

export default getResource
