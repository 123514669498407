export const secretariat = {
	GET_SECRETARIAT: "secretariat/get",
	GET_SECRETARIAT_DETAIL: "secretariat/detail",
	GET_ITEM_DETAIL: "secretariat/item/detail",
	ADD_ITEM: "secretariat/item/add",
	UPDATE_ITEM: "secretariat/item/update",
	DELETE_ITEM: "secretariat/item/delete",
}

export default secretariat
