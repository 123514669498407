import getVideos from "./getVideos"
import addVideo from "./addVideo"
import getVideoDetail from "./getVideoDetail"
import deleteVideo from "./deleteVideo"
import changeTypeVideo from "./changeTypeVideo"
import getStreamingVideo from "./getStreamingVideo"
import getSpecialGuest from "./getSpecialGuest"
import updateVideo from "./updateVideo"

export {
	getVideos,
	addVideo,
	getVideoDetail,
	deleteVideo,
	changeTypeVideo,
	getSpecialGuest,
	getStreamingVideo,
	updateVideo,
}

const video = {
	getVideos,
	addVideo,
	getVideoDetail,
	deleteVideo,
	changeTypeVideo,
	getSpecialGuest,
	getStreamingVideo,
	updateVideo,
}

export default video
