import { axiosInstance } from "../../../helper"
import { video } from "../types"

export function getVideos(params, config) {
	return {
		type: video.GET_VIDEO,
		payload: new axiosInstance.get("/admin/streaming-video", {
			params,
			...config,
		}),
	}
}

export default getVideos
