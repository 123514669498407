import { promiseState } from "../../helper"
import { role } from "../actions/types"

const { pendingState, fulfilledState, rejectedState } = promiseState

const initialState = {
	isLoading: false,
	message: null,
	errors: [],
	data: [],
}

const roleReducer = (state = initialState, action) => {
	switch (action.type) {
		case pendingState(role.GET): {
			return Object.assign({}, state, {
				isLoading: true,
			})
		}

		case rejectedState(role.GET): {
			return Object.assign({}, state, {
				isLoading: false,
			})
		}

		case fulfilledState(role.GET): {
			return Object.assign({}, state, {
				isLoading: false,
				data: action.payload.data.data,
			})
		}

		default:
			return state
	}
}

export default roleReducer
