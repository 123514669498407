import { Component } from "react"
import { connect } from "react-redux"
import {
	Button,
	Card,
	CardHeader,
	CardBody,
	FormGroup,
	Form,
	Input,
	InputGroupAddon,
	InputGroupText,
	InputGroup,
	Col,
} from "reactstrap"
import { auth } from "../../../redux/actions"

import {
	getAuth,
	signInWithPopup,
	GoogleAuthProvider,
	OAuthProvider,
} from "firebase/auth"

class Login extends Component {
	constructor(props) {
		super(props)

		this.state = {
			email: null,
			password: null,
		}
	}

	googleLogin() {
		const auth = getAuth()
		const provider = new GoogleAuthProvider()
		signInWithPopup(auth, provider)
			.then((result) => {
				// This gives you a Google Access Token. You can use it to access the Google API.
				const credential = GoogleAuthProvider.credentialFromResult(result)
				const token = credential.accessToken

				console.log(result, token)
				// The signed-in user info.
				const user = result.user
				console.log(user)
				// ...
			})
			.catch((error) => {
				// Handle Errors here.
				const credential = GoogleAuthProvider.credentialFromError(error)
				console.log(error, credential)
			})
	}

	appleLogin() {
		const provider = new OAuthProvider("apple.com")
		const auth = getAuth()
		signInWithPopup(auth, provider)
			.then((result) => {
				console.log(result)

				// ...
			})
			.catch((error) => {
				console.log(error)
			})
	}

	onLogin(e) {
		e.preventDefault()

		this.props.login({
			email: this.state.email,
			password: this.state.password,
		})
	}

	componentDidMount() {}

	render() {
		return (
			<>
				<Col lg='5' md='7'>
					<Card className='border-0'>
						<CardHeader className='pb-5'>
							<div className="d-flex justify-content-center">
								<img src={require("../../../assets/glowfc/img/logo.jpeg").default} alt="logo.jpeg" width={100} />
							</div>
							{/* <div className='text-muted text-center mt-2 mb-3'>
								<small>Sign in</small>
							</div> */}
							{/* <div className='btn-wrapper text-center'>
								<Button
									className='btn-neutral btn-icon'
									color='default'
									type='button'
									onClick={this.appleLogin.bind(this)}
								>
									<span className='btn-inner--icon'>
										<img
											alt='...'
											src={
												require("../../../assets/img/icons/common/apple.svg")
													.default
											}
										/>
									</span>
									<span className='btn-inner--text'>Apple</span>
								</Button>
								<Button
									className='btn-neutral btn-icon'
									color='default'
									type='button'
									onClick={this.googleLogin.bind(this)}
								>
									<span className='btn-inner--icon'>
										<img
											alt='...'
											src={
												require("../../../assets/img/icons/common/google.svg")
													.default
											}
										/>
									</span>
									<span className='btn-inner--text'>Google</span>
								</Button>
							</div> */}
						</CardHeader>
						<CardBody className='px-lg-5 py-lg-5'>
							{/* <div className='text-center text-muted mb-4'>
								<small>Or sign in with credentials</small>
							</div> */}
							<Form role='form' onSubmit={this.onLogin.bind(this)}>
								<FormGroup className='mb-3'>
									<InputGroup className='input-group-alternative'>
										<InputGroupAddon addonType='prepend'>
											<InputGroupText>
												<i className='ni ni-email-83' />
											</InputGroupText>
										</InputGroupAddon>
										<Input
											placeholder='Email'
											type='email'
											autoComplete='new-email'
											onChange={(e) => {
												this.setState({
													email: e.target.value,
												})
											}}
										/>
									</InputGroup>
								</FormGroup>
								<FormGroup>
									<InputGroup className='input-group-alternative'>
										<InputGroupAddon addonType='prepend'>
											<InputGroupText>
												<i className='ni ni-lock-circle-open' />
											</InputGroupText>
										</InputGroupAddon>
										<Input
											placeholder='Password'
											type='password'
											autoComplete='new-password'
											onChange={(e) => {
												this.setState({
													password: e.target.value,
												})
											}}
										/>
									</InputGroup>
								</FormGroup>
								<div className='text-center'>
									<Button className='my-4' color='primary' type='submit'>
										Sign in
									</Button>
								</div>
							</Form>
						</CardBody>
					</Card>
				</Col>
			</>
		)
	}
}

export default connect(null, {
	login: auth.login,
})(Login)
