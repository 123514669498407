import axios from "axios"
import { store } from "../redux/store"
import { auth } from "../redux/actions"
import { toast } from "react-toastify"
const { REACT_APP_API_URL } = process.env
const { dispatch } = store

const axiosInstance = axios.create({
	baseURL: REACT_APP_API_URL,
	headers: {
		accesstoken: store.getState().auth.data.token,
	},
})

axiosInstance.interceptors.request.use((config) => {
	config.headers["accesstoken"] = store.getState().auth.data.token

	return config
})

axiosInstance.interceptors.response.use(
	(response) => {
		return new Promise((resolve) => {
			resolve(response)
		})
	},

	async (error) => {
		if (!error.response) {
			return Promise.reject(error)
		}

		if (error.response.status !== 401) {
			return Promise.reject(error)
		}

		toast.error("Sesi telah berakhir mohon login kembali")
		dispatch(auth.logout())

		return Promise.reject(error)
	}
)

export default axiosInstance
