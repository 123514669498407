import { axiosInstance } from "../../../helper"
import { instagram } from "../types"

export function getIgProfile(params, config) {
	return {
		type: instagram.GET_IG_PROFILE,
		payload: new axiosInstance.get(`/admin/instagram-profile/`, {
			...config,
			params,
		}),
	}
}

export default getIgProfile
