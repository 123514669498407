import { promiseState } from "../../helper"
import { ministry } from "../actions/types"

const { pendingState, fulfilledState, rejectedState } = promiseState

const initialState = {
	isLoading: false,
	message: null,
	errors: [],
	data: [],
	content: {},
}

const ministryReducer = (state = initialState, action) => {
	switch (action.type) {
		case pendingState(ministry.GET_MINISTRIES): {
			return Object.assign({}, state, {
				isLoading: true,
			})
		}

		case rejectedState(ministry.GET_MINISTRIES): {
			return Object.assign({}, state, {
				isLoading: false,
			})
		}

		case fulfilledState(ministry.GET_MINISTRIES): {
			return Object.assign({}, state, {
				isLoading: false,
				data: action.payload.data.data,
			})
		}
		case pendingState(ministry.GET_MINISTRY_CONTENT): {
			return Object.assign({}, state, {
				isLoading: true,
			})
		}

		case rejectedState(ministry.GET_MINISTRY_CONTENT): {
			return Object.assign({}, state, {
				isLoading: false,
			})
		}

		case fulfilledState(ministry.GET_MINISTRY_CONTENT): {
			return Object.assign({}, state, {
				isLoading: false,
				content: action.payload.data.data,
			})
		}

		default:
			return state
	}
}

export default ministryReducer
