import {
	Card,
	CardHeader,
	CardFooter,
	Table,
	Container,
	Row,
	BreadcrumbItem,
	Breadcrumb,
} from "reactstrap"
// core components
import { Component } from "react"
import { Link } from "react-router-dom"
import ReactPaginate from "react-paginate"
import { connect } from "react-redux"
import { church } from "../../../redux/actions"

class Church extends Component {
	constructor(props) {
		super(props)

		this.state = {
			params: {
				limit: 10,
				page: 1,
			},
		}
	}

	getChurches() {
		this.props.getChurches(this.state.params)
	}

	componentDidMount() {
		this.getChurches()
	}

	handlePageClick(e) {
		const oldState = { ...this.state }

		oldState.params.page = e.selected + 1

		this.setState(oldState, () => {
			this.getUsers()
		})
	}

	render() {
		return (
			<>
				{/* Page content */}
				<Container fluid className='mb-5'>
					<div>
						<Breadcrumb>
							<BreadcrumbItem active>Data Gereja</BreadcrumbItem>
						</Breadcrumb>
					</div>
					{/* Table */}
					<Row>
						<div className='col'>
							<Card>
								<CardHeader className='d-flex'>
									<h3 className='mr-auto'>Data Gereja</h3>
								</CardHeader>
								<CardHeader className='d-flex'>
									<Link
										to={{
											pathname: "/admin/church/create",
										}}
										className='btn btn-primary ml-auto'
									>
										Tambah Gereja
									</Link>
								</CardHeader>
								<Table
									className='align-items-center table-flush table-striped'
									responsive
								>
									<thead className='thead-light'>
										<tr>
											<th scope='col'>No.</th>
											<th scope='col'>Nama Gereja</th>
											<th scope='col'>Kapasitas</th>
											<th scope='col'>Action</th>
											<th></th>
											<th></th>
											<th></th>
											<th></th>
											<th></th>
											<th></th>
										</tr>
									</thead>
									<tbody>
										{this.props.church.data.map((church, i) => (
											<tr>
												<td>
													{(this.props.church.page.currentPage - 1) *
														this.state.params.limit +
														i +
														1}
												</td>
												<td>{church.name}</td>
												<td>{church.capacity}</td>
												<td>
													<Link
														to={{
															pathname: `/admin/church/${church.id}`,
														}}
													>
														Lihat Detail
													</Link>
												</td>
												<td></td>
												<td></td>
												<td></td>
												<td></td>
												<td></td>
												<td></td>
											</tr>
										))}
									</tbody>
								</Table>
								<CardFooter className='py-4 d-flex justify-content-end align-items-center'>
									<p>
										Menampilkan {this.props.church.data.length} dari{" "}
										{this.props.church.page.totalData} data
									</p>
									<ReactPaginate
										breakLabel='...'
										nextLabel='&raquo;'
										onPageChange={this.handlePageClick.bind(this)}
										pageRangeDisplayed={1}
										pageCount={this.props.church.page.totalPage}
										marginPagesDisplayed={1}
										previousLabel='&laquo;'
										renderOnZeroPageCount={null}
										className='pagination ml-3'
										pageClassName='page-item'
										pageLinkClassName='page-link'
										activeClassName='active'
										disabledClassName='disabled'
										nextClassName='page-item'
										breakClassName='page-item'
										nextLinkClassName='page-link'
										previousClassName='page-item'
										previousLinkClassName='page-link'
										breakLinkClassName='page-link'
									/>
								</CardFooter>
							</Card>
						</div>
					</Row>
				</Container>
			</>
		)
	}
}

const mapStateToProps = (state) => {
	return {
		church: state.church,
	}
}

const mapDispatchToProps = {
	getChurches: church.getChurches,
}
export default connect(mapStateToProps, mapDispatchToProps)(Church)
