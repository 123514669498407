import { dashboard } from "../types"
import axiosInstance from "../../../helper/axiosInstance"

export function getUserCard(params, config) {
	return {
		type: dashboard.USER,
		payload: new axiosInstance.get("/admin/dashboard/user-chart", {
			params,
			...config,
		}),
	}
}

export default getUserCard
