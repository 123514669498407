import { axiosInstance } from "../../../helper"
import { church } from "../types"

export function updateChurch(id, data, config) {
	return {
		type: church.UPDATE_CHURCH,
		payload: new axiosInstance.put(`/church/${id}`, data, config),
	}
}

export default updateChurch
