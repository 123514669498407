import { promiseState } from "../../helper"
import { secretariat } from "../actions/types"

const { pendingState, fulfilledState, rejectedState } = promiseState

const initialState = {
	isLoading: false,
	message: null,
	errors: [],
	data: [],
	detail: {},
	itemDetail: {},
}

const secretariatReducer = (state = initialState, action) => {
	switch (action.type) {
		case pendingState(secretariat.GET_SECRETARIAT): {
			return Object.assign({}, state, {
				isLoading: true,
			})
		}

		case rejectedState(secretariat.GET_SECRETARIAT): {
			return Object.assign({}, state, {
				isLoading: false,
			})
		}

		case fulfilledState(secretariat.GET_SECRETARIAT): {
			return Object.assign({}, state, {
				isLoading: false,
				data: action.payload.data.data,
			})
		}

		case pendingState(secretariat.GET_SECRETARIAT_DETAIL): {
			return Object.assign({}, state, {
				isLoading: true,
			})
		}

		case rejectedState(secretariat.GET_SECRETARIAT_DETAIL): {
			return Object.assign({}, state, {
				isLoading: false,
			})
		}

		case fulfilledState(secretariat.GET_SECRETARIAT_DETAIL): {
			return Object.assign({}, state, {
				isLoading: false,
				detail: action.payload.data.data,
			})
		}

		case pendingState(secretariat.GET_ITEM_DETAIL): {
			return Object.assign({}, state, {
				isLoading: true,
			})
		}

		case rejectedState(secretariat.GET_ITEM_DETAIL): {
			return Object.assign({}, state, {
				isLoading: false,
			})
		}

		case fulfilledState(secretariat.GET_ITEM_DETAIL): {
			return Object.assign({}, state, {
				isLoading: false,
				itemDetail: action.payload.data.data,
			})
		}

		default:
			return state
	}
}

export default secretariatReducer
