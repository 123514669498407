import { axiosInstance } from "../../../helper"
import { church } from "../types"

export function deleteChurch(id, config) {
	return {
		type: church.DELETE_CHURCH,
		payload: new axiosInstance.delete(`/church/` + id, config),
	}
}

export default deleteChurch
