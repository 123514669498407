import { dashboard } from "../types"
import axiosInstance from "../../../helper/axiosInstance"

export function getCard(params, config) {
	return {
		type: dashboard.CARD,
		payload: new axiosInstance.get("/admin/dashboard/card", {
			params,
			...config,
		}),
	}
}

export default getCard
