import { axiosInstance } from "../../../helper"
import { sacrament } from "../types"

export function getBaptisms(params, config) {
	return {
		type: sacrament.GET_BAPTISM,
		payload: new axiosInstance.get("/admin/sacrament/baptism", {
			...config,
			params,
		}),
	}
}

export default getBaptisms
