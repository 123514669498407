import { axiosInstance } from "../../../helper"
import { secretariat } from "../types"

export function updateItem(id, data, config) {
	return {
		type: secretariat.UPDATE_ITEM,
		payload: new axiosInstance.put(
			`/admin/secretariat-item/${id}`,
			data,
			config
		),
	}
}

export default updateItem
